export const initialState = {};
const missions = {
  name: "missions",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchMissions: () => ({ apiFetch }) => {
    return apiFetch("missions").then(response => response.data);
  }
}
export default missions;
