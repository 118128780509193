import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'redux-bundler-react';

import { useToggle } from '../../../hooks/UseToggle';
import { ReactComponent as Bell } from '../../../images/svg/icons-agora/bell.svg';
import { EventType } from '../../../types/events';
import { SortTypes } from '../../../types/pagination';
import { Post } from '../../../types/post';
import { ProjectHeaderType } from '../../../types/project';

interface PropTypes {
  lastConnexion: string;
  projects?: ProjectHeaderType[];
  doFetchProjects: Function;
  doFetchEvents: (filters: { limit: number }) => Promise<{ data: EventType[] }>;
  feed?: Post[];
  doFetchNews: Function;
}

const HeaderNotif: FunctionComponent<PropTypes> = ({
  lastConnexion,
  projects,
  doFetchProjects,
  doFetchEvents,
  feed,
  doFetchNews
}) => {
  const [newElements, setNewElements] = useState({
    events: [],
    news: [],
    projects: []
  });
  const [lastConnexionDate, setLastConnexionDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<EventType[]>([]);
  const [notifNumber, setNotifNumber] = useState<number>(0);
  const containerRef = useRef(null);
  const [isDropDownOpen, toggleIsDropDownOpen] = useToggle(false);
  const [onlyOnce, toggleOnlyOnce] = useToggle(false);
  const dropElementStyle =
    "bg-neutral-1 border border-neutral-3 shadow hover:border-primary-8 p-3 text-neutral-8 hover:text-neutral-1 hover:bg-primary-8";

  useEffect(() => {
    if (lastConnexion && !onlyOnce) {
      setLastConnexionDate(new Date(lastConnexion));
      doFetchProjects({ sort: SortTypes.DATE, clearActiveProject: false });
      doFetchEvents({ limit: 20 }).then(events => setEvents(events.data));
      doFetchNews();
      toggleOnlyOnce();
    }
  }, [lastConnexion]);

  useEffect(() => getNewElements("events", events), [events]);
  useEffect(() => getNewElements("news", feed), [feed]);
  useEffect(() => getNewElements("projects", projects), [projects]);

  useEffect(() => {
    if (isDropDownOpen) {
      document.addEventListener("mousedown", handleClickOutside, {
        once: true
      });
    } else {
      document.removeEventListener("mousedown", handleClickOutside, true);
    }
  }, [containerRef, isDropDownOpen]);

  function handleClickOutside(event) {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      toggleIsDropDownOpen();
    }
  }

  function getNewElements(type, elements) {
    const filteredElements = (elements || []).filter(e =>
      e.creationDate ? new Date(e.creationDate) > lastConnexionDate : false
    );
    setNewElements(Object.assign(newElements, { [type]: filteredElements }));
    let sum =
      newElements.events.length +
      newElements.projects.length +
      newElements.news.length;

    setNotifNumber(sum);
  }

  function getDropElement(
    element: ProjectHeaderType | EventType | Post,
    type: string
  ) {
    let image;
    let link;

    switch (type) {
      case "event":
        link = `/events/${element.id}`;
        // @ts-ignore
        if (element.images && element.images.length > 0) {
          // @ts-ignore
          image = element.images[0].id_image;
        }
        break;
      case "project":
        link = `/projects/${element.id}`;
        // @ts-ignore
        if (element.fileImage) {
          // @ts-ignore
          image = element.fileImage;
        }
        break;
      case "article":
        link = `/resources/articles/${element.id}`;
        // @ts-ignore
        if (element.image_in_s3) {
          // @ts-ignore
          image = element.image_in_s3;
        }
        break;
      default:
    }

    return (
      <li key={element.title} className={dropElementStyle}>
        <a href={link} className="flex flex-row items-center">
          <div
            className="mr-3 bg-cover"
            style={{ padding: "25px", backgroundImage: `url(/${image})` }}
          />
          <span className="truncate">{element.title}</span>
        </a>
      </li>
    );
  }

  return notifNumber ? (
    <div
      className="header-notif hidden lg:block"
      onClick={toggleIsDropDownOpen}
      ref={containerRef}
    >
      <div className="relative">
        <Bell className="h-6 fill-current mr-1" />
        <div className="notif-bell">{notifNumber}</div>
        {isDropDownOpen && (
          <ul className="notif-drop">
            {newElements.events.map((element, index) =>
              index < 2 ? getDropElement(element, "event") : ""
            )}
            {newElements.news.map((element, index) =>
              index < 2 ? getDropElement(element, "article") : ""
            )}
            {newElements.projects.map((element, index) =>
              index < 2 ? getDropElement(element, "project") : ""
            )}
          </ul>
        )}
      </div>
    </div>
  ) : null;
};

export default connect(
  "selectLastConnexion",
  "selectProjects",
  "doFetchProjects",
  "doFetchEvents",
  "selectFeed",
  "doFetchNews",
  HeaderNotif
);
