import { oktaClient } from "./factories/oktaClient";

const storeTokens = () =>
  oktaClient.token
    .parseFromUrl()
    .then(tokens => {
      tokens = Array.isArray(tokens) ? tokens : [tokens];
      for (let token of tokens) {
        if (token.idToken) {
          oktaClient.tokenManager.add("idToken", token);
        } else if (token.accessToken) {
          oktaClient.tokenManager.add("accessToken", token);
        }
      }
    })
    .catch(error => console.error("error", error));

const getToken = tokenName => oktaClient.tokenManager.get(tokenName);

const containsAuthTokens = /id_token|access_token|code/;
const isTokenInURL = () => {
  const { location } = window;
  return location && location.hash && containsAuthTokens.test(location.hash);
};

const redirectToOktaLogin = () => {
  localStorage.setItem("currentUrl", window.location);
  oktaClient.token.getWithRedirect({
    responseType: ["id_token", "token"],
    scopes: ["openid", "email", "profile"]
  });
};

const checkRedirectUrl = redirectTo => {
  const redirectUrl = localStorage.getItem("currentUrl");
  localStorage.removeItem("currentUrl");
  if (redirectUrl && redirectUrl !== window.location) {
    redirectTo(redirectUrl);
  }
};

const logout = () => {
  oktaClient.tokenManager.clear();
  return oktaClient.signOut();
};

export {
  storeTokens,
  getToken,
  isTokenInURL,
  redirectToOktaLogin,
  checkRedirectUrl,
  logout
};
