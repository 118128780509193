import { FunctionComponent } from "react";
import { Post } from "../../types/post";
import { CardTag } from "../CardTag/CardTag";
import { parseDate } from "../../services/utils";
import classNames from "classnames";
import { CardArticle } from "./CardArticle";
import { Header } from "./Header";
import { connect } from "redux-bundler-react";
import { BookmarkStar } from "../BookmarkStar/BookmarkStar";
import { ContentTypes } from "../../types/inspirations";

interface NewsCardWithoutConnectPropTypes extends Post {
  isSmall?: boolean;
  onBookmarkClick?: (content: {
    contentId: string;
    type: ContentTypes;
    isBookmarked: boolean;
  }) => Promise<{}>;
  canBeUnlinked?: boolean;
  isFluid?: boolean;
}

export interface NewsCardPropTypes extends NewsCardWithoutConnectPropTypes {
  userId: string;
}

export const NewsCard: FunctionComponent<NewsCardPropTypes> = ({
  id,
  creationDate,
  text,
  image,
  image_in_s3,
  title,
  url,
  user,
  tags = [],
  isSmall = false,
  userId,
  isBookMarked,
  onBookmarkClick,
  canBeUnlinked = false,
  isFluid = false
}) => {
  function onBookmarkPost(bookmarkState: boolean) {
    return onBookmarkClick
      ? onBookmarkClick({
          contentId: id || "",
          type: ContentTypes.POST,
          isBookmarked: bookmarkState
        })
      : Promise.resolve({});
  }

  const textContainerClasses = classNames({
    "px-6 mb-4": !isSmall,
    "px-4 mb-2": isSmall
  });

  const textClasses = classNames("text-neutral-6 whitespace-pre-line", {
    "text-base": !isSmall,
    "text-sm": isSmall
  });

  const tagContainerClasses = classNames(
    "flex flex-wrap justify-center border-t border-neutral-2",
    {
      "px-6 pt-4 pb-2": !isSmall,
      "px-4 pt-2": isSmall
    }
  );

  const isAuthor = user.id === userId;
  const isNotPreviewCard = id !== undefined;
  const canBeDeleted = isAuthor && isNotPreviewCard;

  const pageLink = id ? `/resources/articles/${id}` : "#";

  return (
    <div
      className={classNames({
        "w-80": !isFluid,
        "sm:w-112 bg-neutral-0 rounded overflow-hidden shadow-md my-1": !isSmall,
        "card-project": isSmall
      })}
      data-cy-card
    >
      {id && <BookmarkStar selected={isBookMarked} onClick={onBookmarkPost} />}
      <Header
        postId={id}
        user={user}
        newsDate={parseDate(creationDate)}
        canBeDeleted={canBeDeleted}
        canBeUnlinked={canBeUnlinked}
      />
      <a
        href={pageLink}
        className={classNames({ "disable-click": pageLink === "#" })}
      >
        <div className={textContainerClasses}>
          <p className={textClasses}>
            {text &&
              url &&
              `${text.substring(0, 100)}${text.length > 100 ? "..." : ""}`}
            {text &&
              !url &&
              `${text.substring(0, 850)}${text.length > 850 ? "..." : ""}`}
          </p>
        </div>
      </a>
      <CardArticle
        articlePicture={{
          src: image_in_s3 ? "/" + image_in_s3 : image,
          alt: title
        }}
        articleTitle={title}
        articlePageUrl={pageLink}
        isSmall={isSmall}
        articleExternalLink={url}
      />
      {tags.length !== 0 && (
        <div className={tagContainerClasses}>
          {tags.map(tag => (
            <CardTag key={tag.id} tag={tag} isSmall={isSmall} />
          ))}
        </div>
      )}
    </div>
  );
};

const connectedNewsCard: FunctionComponent<
  NewsCardWithoutConnectPropTypes
> = connect(
  "selectUserId",
  NewsCard
);

export default connectedNewsCard;
