import { FunctionComponent } from "react";

interface PropTypes {
  title: string;
  subTitle?: string;
  backgroundImage?: string;
  backgroundPositionY?: string;
}

const HeaderImage: FunctionComponent<PropTypes> = ({
  title,
  subTitle,
  backgroundImage,
  backgroundPositionY = "50%"
}) => (
  <div
    className="w-full bg-black bg-cover bg-center"
    style={{
      backgroundImage: backgroundImage
        ? `url(${backgroundImage})`
        : "linear-gradient(to right bottom, #003e9b, #1d4bb3, #3258cc, #4466e5, #5774ff)",
      backgroundPositionY: backgroundPositionY
    }}
  >
    <div className="container pt-16 pb-10 px-8 text-white m-auto">
      <div className="text-4xl mb-3">{title}</div>
      <div className="text-lg">{subTitle}</div>
    </div>
  </div>
);

export default HeaderImage;
