import { FunctionComponent, useCallback } from "react";
import { connect } from "redux-bundler-react";
import { Trans } from "@lingui/macro";
import { ButtonKind } from "../../../components/Button/Button";
import { SimpleModal } from "../../../components/Modal/SimpleModal";
import { ContentTypes } from "../../../types/inspirations";

interface UnlinkInspirationWithoutConnectPropTypes {
  id: string;
  closeModal: () => void;
  isOpen: boolean;
  type: ContentTypes;
}

interface UnlinkInspirationPropTypes
  extends UnlinkInspirationWithoutConnectPropTypes {
  doDeleteInspiration?: ({
    contentId: string,
    type: ContentTypes
  }) => Promise<void>;
  doUpdateRefreshCounter?: ({ type: ContentTypes }) => void;
}

export const UnlinkInspirationModal: FunctionComponent<
  UnlinkInspirationPropTypes
> = ({
  id,
  closeModal,
  isOpen,
  doDeleteInspiration,
  doUpdateRefreshCounter,
  type
}) => {
  const deleteInspiration = useCallback(
    () =>
      doDeleteInspiration
        ? doDeleteInspiration({ contentId: id, type })
        : Promise.resolve(),
    [doDeleteInspiration, id, type]
  );

  const onDeleteSuccess = useCallback(
    () => (doUpdateRefreshCounter ? doUpdateRefreshCounter({ type }) : null),
    [doUpdateRefreshCounter, type]
  );

  return (
    <SimpleModal
      onClick={deleteInspiration}
      onClickSuccess={onDeleteSuccess}
      header={<Trans id="Unlink inspiration ?" component={null}>Unlink inspiration ?</Trans>}
      closeModal={closeModal}
      isOpen={isOpen}
      buttonKind={ButtonKind.warning}
    />
  );
};

const connectedModal: FunctionComponent<
  UnlinkInspirationWithoutConnectPropTypes
> = connect(
  "doDeleteInspiration",
  "doUpdateRefreshCounter",
  UnlinkInspirationModal
);

export default connectedModal;
