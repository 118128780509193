export enum PostModals {
  INSPIRATION_LINK = "inspiration_link",
  INSPIRATION_UNLINK = "inspiration_unlink",
  DELETE_POST = "delete_post"
}

export enum ProjectModals {
  DELETE_PROJECT = "delete_project"
}

export enum InitiativeModals {
  DELETE_INITIATIVE = "delete_initiative"
}

export enum EventModals {
  DELETE_EVENT = "delete_event"
}
