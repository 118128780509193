import "./index.css";
import "react-multi-carousel/lib/styles.css";
// import ReactDOM from "react-dom";

import getStore from "./bundles";
import rootStore from "./pages/root";
import * as serviceWorker from "./serviceWorker";

import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(rootStore(getStore()))
// ReactDOM.render(root(getStore()), document.getElementById("root"));
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
