import { ContentTypes } from "../types/inspirations";

export const initialState = {};

const inspirations = {
  name: "inspirations",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },

  doBookmarkContent: ({ contentId, type, isBookmarked }) => ({
    apiFetch,
    store
  }) => {
    const methodType = isBookmarked ? "DELETE" : "POST";
    return apiFetch("users/bookmark", {
      method: methodType,
      body: { contentId, type }
    }).then(() => store.doUpdateRefreshCounter({ type }));
  },

  doFetchProjectsToLink: (type, id) => ({ apiFetch }) =>
    apiFetch(`projects/me/inspiration?type=${type}&contentId=${id}`),

  doPostAssociatedProjects: (type, contentId, projectIds) => ({
    apiFetch,
    store
  }) =>
    apiFetch("projects/inspiration", {
      method: "POST",
      body: { contentId, type, projectIds }
    }).then(response => {
      store.doUpdateRefreshCounter({ type: ContentTypes.PROJECT });
      return response;
    }),

  doPutAssociatedProjects: (type, contentId, projectIds) => ({
    apiFetch,
    store
  }) =>
    apiFetch("projects/inspiration", {
      method: "PUT",
      body: { contentId, type, projectIds }
    }).then(response => {
      store.doUpdateRefreshCounter({ type: ContentTypes.PROJECT });
      return response;
    }),

  doDeleteInspiration: ({ type, contentId }) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/inspiration`, {
      method: "DELETE",
      body: { contentId, type }
    });
  },

  doFetchPostInspirations: projectId => ({ apiFetch }) =>
    apiFetch(`projects/${projectId}/inspiration/posts`),

  doFetchProjectInspirations: projectId => ({ apiFetch }) =>
    apiFetch(`projects/${projectId}/inspiration/projects/header`),

  doFetchInitiativeInspirations: projectId => ({ apiFetch }) =>
    apiFetch(`projects/${projectId}/inspiration/initiatives/header`)
};

export default inspirations
