import { ChangeEvent, FunctionComponent } from "react";
import { ProjectHeaderType } from "../../types/project";
import classNames from "classnames";

export interface ProjectInspiration extends ProjectHeaderType {
  isAssociated: boolean;
}

interface ProjectInspirationPropTypes {
  project: ProjectInspiration;
  isAssociated: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ProjectInspirationInput: FunctionComponent<
  ProjectInspirationPropTypes
> = ({ project, isAssociated, onChange }) => {
  const computedClasses = classNames(
    "flex items-center py-2 px-4 cursor-pointer",
    {
      "hover:bg-neutral-2": !project.isAssociated
    }
  );

  return (
    <li>
      <label className={computedClasses}>
        <input
          className="mr-2"
          type="checkbox"
          name={project.id}
          checked={isAssociated}
          onChange={onChange}
          data-cy-project-inspiration-input
        />
        <div
          className="bg-cover bg-center h-16 w-16 mr-2"
          style={{ backgroundImage: `url(${project.image.url}` }}
        />
        <span className="flex-basis">{project.title}</span>
      </label>
    </li>
  );
};
