/* eslint-disable @typescript-eslint/no-unused-vars */
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

import {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useState
} from "react";
import { connect } from "redux-bundler-react";
import { TagCategories, TagType } from "../../types/tag";

interface PropTypes {
  doPostTag: (tag: {
    label: string;
    category: string;
    buId: number;
  }) => Promise<any>;
  doFetchTags: () => void;
  selectedBUTags: TagType[];

}

export const BeForm: FunctionComponent<PropTypes> = ({
  doPostTag,
  doFetchTags,
  selectedBUTags,
}) => {
  const [newBe, setNewBe] = useState<string>("");
  const [isNewBeVisible, setIsNewBeVisible] = useState<boolean>(false);
  const [isNewBeLoading, setIsNewBeLoading] = useState<boolean>(false);
  const [isNewBeSuccess, setIsNewBeSuccess] = useState<boolean>(false);

  function createNewBE() {
    setIsNewBeLoading(true);
    setIsNewBeSuccess(false);
    doPostTag({
      label: newBe,
      category: TagCategories.BE,
      buId: selectedBUTags[0].id
    }).then(() => {
      doFetchTags();
      setNewBe("");
      setIsNewBeLoading(false);
      setIsNewBeSuccess(true);
    });
  }

  function updateBeField(e: ChangeEvent<any>) {
    setNewBe(e.target.value);
  }

  return (
    <Fragment>
      {/* {selectedBUTags.length > 0 && (
        <div className="flex flex-col items mt-2 text-neutral-6">
          <div className="flex items-center mr-4 mb-4">
            <span className="mr-4">
              <Trans>Can't find your BE ?</Trans>
            </span>
            {!isNewBeVisible && (
              <Button onClick={() => setIsNewBeVisible(true)}>
                <Trans>Create a new one</Trans>
              </Button>
            )}
          </div>
          {isNewBeVisible && (
            <div className="flex">
              <div className="mr-4">
                <Input
                  placeholder={i18n._(t`BE Name`)}
                  id="newBe"
                  name="newBe"
                  value={newBe}
                  mask="BE "
                  onChange={updateBeField}
                  required
                  maxLength={150}
                  disabled={isNewBeLoading}
                />
              </div>
              <div className="flex items-end mr-4">
                <Button onClick={createNewBE} isLoading={isNewBeLoading}>
                  <Trans>Create</Trans>
                </Button>
              </div>
            </div>
          )}
        </div>
      )} */}
      {isNewBeSuccess && (
        <div className="text-success-8 text-center mt-2">
          <Trans>New BE was successfully created !</Trans>
        </div>
      )}
    </Fragment>
  );
};

export default connect(
  "doFetchTags",
  "doPostTag",
  (BeForm)
);
