import { FunctionComponent } from "react";

interface PropTypes {
  radius: number;
  stroke: number;
  progress: number;
}

export const FillingProgress: FunctionComponent<PropTypes> = ({
  radius,
  stroke,
  progress
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  let color;
  if (progress < 25) {
    color = "text-warning-8";
  } else if (progress < 75) {
    color = "text-primary-8";
  } else if (progress <= 100) {
    color = "text-success-8";
  }

  return (
    <div title={`${progress} %`}>
      <svg height={radius * 2} width={radius * 2} className="stroke-current">
        <circle
          fill="transparent"
          className="text-neutral-3"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={`${color}`}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};
