import { FunctionComponent } from "react";
import { TagType } from "../../types/tag";

interface TagItemPropTypes {
  tag: TagType;
  onTagClick: (tag: TagType) => void;
}

export const TagItem: FunctionComponent<TagItemPropTypes> = ({
  tag,
  onTagClick
}) => {
  function onClick() {
    onTagClick(tag);
  }
  return (
    <li key={tag.id}>
      <button
        type="button"
        className="bg-neutral-1 hover:bg-primary-8 focus:bg-primary-8 border border-t-0 border-neutral-3 hover:border-primary-8 shadow focus:border-primary-8 flex items-center w-full p-2 focus:outline-none text-neutral-6 hover:text-neutral-1 focus:text-neutral-1"
        onClick={onClick}
        data-tag-search-button
      >
        {tag.label}
      </button>
    </li>
  );
};
