import { ChangeEvent, FunctionComponent } from "react";

interface PropTypes {
  onFilterChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
  title?: string;
  radioItems: { label: string; value: string }[];
  name?: string;
}

export const RadioFilters: FunctionComponent<PropTypes> = ({
  onFilterChange,
  selectedValue,
  title = "",
  name,
  radioItems
}) => (
  <div className="flex flex-col items-center my-8 text-neutral-8">
    {title ? (
      <div className="mb-4 font-bold text-neutral-6">{title}:</div>
    ) : null}
    <div className="flex">
      {radioItems.map(radioItem => (
        <div className="mr-4" key={radioItem.value}>
          <label className="flex ml-2" htmlFor={radioItem.value}>
            <input
              type="radio"
              id={radioItem.value}
              name={name}
              value={radioItem.value}
              onChange={onFilterChange}
              checked={radioItem.value === selectedValue}
              data-test-radio-explore
            />
            <span className="ml-2">{radioItem.label}</span>
          </label>
        </div>
      ))}
    </div>
  </div>
);
