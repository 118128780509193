import { Fragment, FunctionComponent, useCallback } from "react";
import { connect } from "redux-bundler-react";
import { ReactComponent as Profile } from "../../../images/svg/icons/icon-user.svg";
import { Loader } from "../../../components/Loader/Loader";
import { DropdownList } from "../../../components/Dropdown/DropdownList/DropdownList";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

interface PropTypes {
  userName?: string;
  userId?: string;
  doLogout?: () => void;
  doUpdateUrl: (url: string) => void;

}

export const HeaderProfile: FunctionComponent<PropTypes> = ({
  userName,
  userId,
  doLogout,
  doUpdateUrl
}) => {


  const handleCallback = useCallback(() => doUpdateUrl(`/profile/${userId}`), [
    userId
  ])

  return userName ? (
    <div className="flex items-center justify-center" data-cy-profile>
      <DropdownList
        buttonLabel={
          <Profile className="h-6 fill-current mr-1 hidden lg:block" />
        }
        withTriangle={false}
        items={[
          {
            label: i18n._(t`Profile`),
            onClick: handleCallback
          },
          { label: "Logout", onClick: doLogout ? doLogout : () => null }
        ]}
      />
    </div>
  ) : (
    <Fragment>
      <Loader isLoading />
    </Fragment>
  )
};

export default connect(
  "selectUserName",
  "selectUserId",
  "doUpdateUrl",
  "doLogout",
  (HeaderProfile)
);
