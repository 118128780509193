import { FunctionComponent } from "react";
import { connect } from "redux-bundler-react";
import { Trans } from "@lingui/macro";
import { ButtonKind } from "../../../components/Button/Button";
import { SimpleModal } from "../../../components/Modal/SimpleModal";

interface DeleteModalWithoutConnectPropTypes {
  closeModal: () => void;
  isOpen: boolean;
  id: number;
  redirectOnDelete?: boolean;
}

interface DeleteModalPropTypes extends DeleteModalWithoutConnectPropTypes {
  doDeleteEvent: (id: number) => Promise<void>;
  doFetchEvents: (limit?: number) => Promise<void>;
  doUpdateUrl: (url: string) => void;
}

export const EventDeleteModal: FunctionComponent<DeleteModalPropTypes> = ({
  closeModal,
  isOpen,
  doDeleteEvent,
  doFetchEvents,
  doUpdateUrl,
  id,
  redirectOnDelete = false
}) => {
  function onClick() {
    return doDeleteEvent(id).then(() => {
      window.location.reload();
    });
  }

  function onClickSuccess() {
    if (redirectOnDelete) {
      doUpdateUrl("/events");
    }
  }

  return (
    <SimpleModal
      onClick={onClick}
      onClickSuccess={onClickSuccess}
      header={<Trans id="Delete event ?" component={null}>Delete event ?</Trans>}
      closeModal={closeModal}
      isOpen={isOpen}
      buttonKind={ButtonKind.error}
    />
  );
};

const connectedModal: FunctionComponent<
  DeleteModalWithoutConnectPropTypes
> = connect(
  "doDeleteEvent",
  "doFetchEvents",
  "doUpdateUrl",
  EventDeleteModal
);

export default connectedModal;
