export const GeneralMetricsFixture = {
  numberOfContributionByTag: [],
  numberOfContributors: 0,
  numberOfPostByTag: [],
  numberOfPostByUser: [],
  numberOfPosts: 0,
  numberOfProjectByTag: [],
  numberOfProjects: 0,
  numberOfUsers: 0,
  numberOfProjectsInIndustrialization: 0,
  numberOfProjectsInProduction: 0,
  numberOfBUsWithSharedProjects: 0
};
