/* eslint-disable @typescript-eslint/no-unused-vars */
import { i18n } from "@lingui/core";
import { t, Trans, Plural } from "@lingui/macro";

import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { checkRedirectUrl } from "../../services/authService";
import { EventType } from "../../types/events";
import { ContentTypes } from "../../types/inspirations";
import { MetricsType } from "../../types/metrics";
import { Post } from "../../types/post";
import { ProjectHeaderType } from "../../types/project";
import { RouteInfoType } from "../../types/routing";
import { getQuickLinks } from "./HomeConfig";
import { GeneralMetricsFixture } from "../../modules/SideColumn/GeneralMetrics/GeneralMetrics.fixture";
import { EmptyNewsCardFixture } from "../../components/NewsCard/NewsCard.fixtures";
import { EmptyProjectCardFixture } from "../../components/ProjectCard/ProjectCard.fixtures";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import HeaderBackground from "../../images/header/home-hero.jpg";
import UnderlineTitle from "../../components/UnderlineTitle/UnderlineTitle";
import { ReactComponent as IconContent } from "../../images/svg/content.svg";
import { ReactComponent as IconLeaderboards } from "../../images/svg/leaderboard.svg";
import { ReactComponent as IconTags } from "../../images/svg/tags.svg";
import { ReactComponent as IconCommunity } from "../../images/svg/community.svg";
import HomeContentList from "./HomeContentList";
import { ReactComponent as ArrowRight } from "../../images/svg/icons/arrow-right.svg";
import EventList from "../../components/EventList/EventList";

interface PropTypes {
  doFetchLeaderboard: () => Promise<[]>;
  doFetchEventsFromToday: () => Promise<{ data: EventType[] }>;
  doFetchMetrics: () => Promise<MetricsType>;
  doFetchLatestProjects: (limit: number) => Promise<ProjectHeaderType[]>;
  doFetchLatestPosts: (limit: number) => Promise<Post[]>;
  doBookmarkContent: (content: {
    contentId: string;
    type: ContentTypes;
    isBookmarked: boolean;
  }) => Promise<{}>;
  doUpdateUrl: (url: string) => void;
  routeInfo: RouteInfoType;
  postRefreshCounter: number;
  projectRefreshCounter: number;
  userId?: string;

}

interface LeaderboardBUType {
  label: string;
  total: number;
}

export const Home: FunctionComponent<PropTypes> = ({
  doFetchLatestProjects,
  doFetchLatestPosts,
  doFetchMetrics,
  doFetchEventsFromToday,
  doBookmarkContent,
  routeInfo,
  postRefreshCounter,
  projectRefreshCounter,
  doUpdateUrl,
  doFetchLeaderboard,
  userId = ""
}) => {
  const [latestProject, setLatestProject] = useState<ProjectHeaderType[]>([
    EmptyProjectCardFixture
  ]);
  const [latestPost, setLatestPost] = useState<Post[]>([EmptyNewsCardFixture]);
  const [metrics, setMetrics] = useState<MetricsType>(GeneralMetricsFixture);
  const [isLatestProjectLoading, setIsLatestProjectLoading] = useState<boolean>(
    true
  );
  const [isLatestPostLoading, setIsLatestPostLoading] = useState<boolean>(true);
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<EventType[]>([]);
  const [isMetricsLoading, setIsMetricsLoading] = useState<boolean>(true);
  const [leaderboard, setLeaderboard] = useState<LeaderboardBUType[]>([]);

  useEffect(() => {
    doFetchLatestProjects(4).then(latestProject => {
      setLatestProject(latestProject);
      setIsLatestProjectLoading(false);
    });

    checkRedirectUrl(doUpdateUrl);

    doFetchEventsFromToday().then(events => {
      setEvents(events.data);
      setIsEventsLoading(false);
    });

    doFetchLeaderboard().then(buList => {
      setLeaderboard(buList.splice(0, 3));
    });
  }, [routeInfo.url]);

  useEffect(() => {
    doFetchLatestPosts(4).then(latestPost => {
      setLatestPost(latestPost);
      setIsLatestPostLoading(false);
    });
  }, [routeInfo.url, postRefreshCounter]);

  useEffect(() => {
    doFetchMetrics().then(metrics => {
      setMetrics(metrics);
      setIsMetricsLoading(false);
    });
  }, [routeInfo.url, projectRefreshCounter, postRefreshCounter]);

  const currentDate = new Date();
  const searchDate = {
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear()
  };

  const isLoading =
    isEventsLoading &&
    isLatestPostLoading &&
    isLatestProjectLoading &&
    isMetricsLoading;

  return (
    <div className="flex flex-col">
      <span
        style={{
          textShadow: "0 0 5px black"
        }}
      >
        <HeaderImage
          title="Agora"
          subTitle={i18n._(t`Learn, Collaborate and Act on data use at ENGIE`)}
          backgroundImage={HeaderBackground}
        />
      </span>
      <div className="container mx-auto px-8">
        <div className="mt-12 flex flex-wrap justify-center mb-4 justify-between">
          {getQuickLinks(userId).map(quickLink => (
            <a
              href={quickLink.link}
              key={quickLink.name}
              className="flex flex-col rounded items-center justify-center bg-center bg-cover"
              style={{
                width: "23%",
                height: 150,
                backgroundImage: `url(${quickLink.image})`,
                textShadow: "0 0 5px black"
              }}
              target={quickLink?.target}
            >
              <div className="text-white text-center uppercase">
                <div className="font-extrabold text-xl">
                  {quickLink.highlight}
                </div>
                <div className="mt-3 font-semibold text-sm">
                  {quickLink.description}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div className="bg-gray-f5f5f5 text-neutral-8 mt-12">
        <div className="container mx-auto px-8 py-6 flex justify-between text-neutral-6">
          <div className="w-1/6">
            <div className="mb-6 font-semibold">
              <UnderlineTitle
                title={i18n._(t`Community`)}
                icon={
                  <IconCommunity className="text-blue-700 h-4 w-auto fill-current mr-2" />
                }
                color="bg-gray-300"
                fontSize="text-sm"
                uppercase
              />
            </div>
            <ul className="uppercase text-xs font-semibold">
              <li className="mb-4">
                <span>{`${metrics.numberOfBUsWithSharedProjects} `}</span>
                <Plural
                  value={metrics.numberOfBUsWithSharedProjects}
                  _0="Entity"
                  one="Entity"
                  other="Entities"
                />
                {`  sharing UC`}
              </li>
              <li className="mb-4">
                <span>{`${metrics.numberOfContributors} `}</span>
                <Trans id="contributors" component={null}>contributors</Trans>
              </li>
              <li className="mb-4">
                <span>{`${metrics.numberOfUsers} `}</span>
                <Trans id="users" component={null}>users</Trans>
              </li>
            </ul>
          </div>

          <div className="w-1/6">
            <div className="mb-6 font-semibold">
              <UnderlineTitle
                title={i18n._(t`Contents`)}
                icon={
                  <IconContent className="text-blue-700 h-4 w-auto fill-current mr-2" />
                }
                color="bg-gray-300"
                fontSize="text-sm"
                uppercase
              />
            </div>
            <ul className="uppercase text-xs font-semibold">
              <li className="mb-4">
                <span>{`${metrics.numberOfProjects} `}</span>
                <Trans id="use cases" component={null}>use cases</Trans>
              </li>
              <li className="mb-4">
                <span>{`${metrics.numberOfProjectsInIndustrialization +
                  metrics.numberOfProjectsInProduction} `}</span>
                <Trans id="industrialized" component={null}>industrialized UC</Trans>
              </li>
              <li className="mb-4">
                <span>{`${metrics.numberOfPosts} `}</span>
                <Trans id="posts" component={null}>posts</Trans>
              </li>
            </ul>
          </div>

          <div className="w-2/6">
            <div className="mb-6 font-semibold">
              <UnderlineTitle
                title={i18n._(t`Popular tags`)}
                icon={
                  <IconTags className="text-blue-700 h-4 w-auto fill-current mr-2" />
                }
                color="bg-gray-300"
                fontSize="text-sm"
                uppercase
              />
            </div>
            <ul className="flex flex-row flex-wrap p-0">
              {metrics.numberOfContributionByTag.slice(0, 10).map(metric => (
                <a
                  href={`/tags/${metric.tag.id}`}
                  key={metric.tag.label}
                  className="hover:bg-neutral-5 hover:text-neutral-1 rounded-full px-3 py-1 text-xs font-semibold mr-2 mb-2 text-neutral-5 bg-neutral-2"
                >
                  <span>{`${metric.tag.label}`}</span> {` (${metric.total})`}
                </a>
              ))}
            </ul>
          </div>

          <div className="w-1/6">
            <div className="text-sm mb-6 font-semibold">
              <UnderlineTitle
                title={i18n._(t`UC Leaderboard`)}
                icon={
                  <IconLeaderboards className="text-blue-700 h-4 w-auto fill-current mr-2" />
                }
                color="bg-gray-300"
                fontSize="text-sm"
                uppercase
              />
            </div>
            <ul>
              {leaderboard.map((bu, index) => {
                let color;
                switch (index) {
                  default:
                  case 0:
                    color = "#0070cf";
                    break;
                  case 1:
                    color = "#00a8e1";
                    break;
                  case 2:
                    color = "#55c1e9";
                    break;
                }

                return (
                  <li
                    key={index}
                    className="flex mb-3 items-center text-sm font-semibold"
                  >
                    <div
                      className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full text-white bg-blue-700 mr-2"
                      style={{
                        backgroundColor: color
                      }}
                    >
                      {index + 1}
                    </div>
                    {`${bu.label} (${bu.total})`}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-8 mt-12 flex text-neutral-6">
        <div style={{ width: "34%" }} className="mr-12 flex flex-col">
          <div className="mb-3">
            <UnderlineTitle
              highlight={i18n._(t`LATEST`)}
              title={i18n._(t`Use Cases`)}
              color="bg-gray-200"
              fontSize="text-2xl"
            />
          </div>
          <div className="flex-1">
            <HomeContentList content={latestProject} />
          </div>
          <a
            href="/projects"
            className="block text-blue-700 text-xs font-bold mt-2 pt-2 flex justify-end border-t-8 border-gray-f5f5f5"
          >
            <div className="uppercase">
              <Trans id="More use case" component={null}>More use case</Trans>
            </div>{" "}
            <ArrowRight className="ml-2 h-4 inline-block fill-current" />
          </a>
        </div>

        <div style={{ width: "34%" }} className="mr-12 flex flex-col">
          <div className="mb-3">
            <UnderlineTitle
              highlight={i18n._(t`LATEST`)}
              title={i18n._(t`Articles`)}
              color="bg-gray-200"
              fontSize="text-2xl"
            />
          </div>
          <div className="flex-1">
            <HomeContentList content={latestPost} />
          </div>
          <a
            href="/resources/articles"
            className="block text-blue-700 text-xs font-bold mt-2 pt-2 flex justify-end border-t-8 border-gray-f5f5f5"
          >
            <div className="uppercase">
              <Trans id="More articles" component={null}>More articles</Trans>
            </div>{" "}
            <ArrowRight className="ml-2 h-4 inline-block fill-current" />
          </a>
        </div>

        <div style={{ width: "32%" }} className="flex flex-col">
          <div className="mb-3">
            <UnderlineTitle
              highlight={i18n._(t`EVENTS`)}
              title=""
              color="bg-gray-200"
              fontSize="text-2xl"
            />
          </div>
          <div className="flex-1 bg-gray-f5f5f5">
            <EventList events={events} isCompact isTitleVisible={false} />
          </div>
          <a
            className="block uppercase text-blue-700 text-xs font-bold mt-2 pt-2 flex justify-end border-t-8 border-gray-f5f5f5"
            href="/events/list"
          >
            <Trans id="All events" component={null}>All events</Trans>{" "}
            <ArrowRight className="ml-2 h-4 inline-block fill-current" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doFetchEventsFromToday",
  "doFetchMetrics",
  "doFetchLatestProjects",
  "doFetchLatestPosts",
  "doFetchLeaderboard",
  "doUpdateUrl",
  "doBookmarkContent",
  "selectRouteInfo",
  "selectPostRefreshCounter",
  "selectProjectRefreshCounter",
  "selectUserId",
  (Home)
);
