/* eslint-disable no-useless-escape */
import { TagType } from "./../types/tag";
import { ProjectHeaderType } from "../types/project";
import { User } from "../types/user";

const ONLY_WHITESPACE = /^\s*$/;

export const getInitials = (name) => {
  if (!name || name.match(ONLY_WHITESPACE)) return "";

  const nameWords = name.trim().split(" ");
  const firstLetter = nameWords[0][0].toUpperCase();

  if (nameWords.length === 1) return firstLetter;

  const secondLetter = nameWords[nameWords.length - 1][0].toUpperCase();
  return firstLetter + secondLetter;
};

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

export const stringToHexColor = (text) => "#" + intToRGB(hashCode(text));

export const getColorWithInitial = (initials) => {
  const colors = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d",
  ];
  const LETTER_A_INDEX = 65;
  let charIndex = initials.charCodeAt(0) - LETTER_A_INDEX;
  if (initials.length > 1) {
    charIndex += initials.charCodeAt(1) - LETTER_A_INDEX;
  }
  const colorIndex = Math.abs(charIndex) % colors.length;
  return colors[colorIndex];
};

export const getCurrentStringDate = () => new Date().toString();

export const parseDate = (dateString) => new Date(dateString);

const urlRegex =
  /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*))/g;
export const isUrl = (url) => !!url && !!url.match(urlRegex);

export const replaceTextLinkToHtmlLink = (url) =>
  url.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

export const replaceNewLineToBr = (url) => url.replace(/\n/g, "<br/>");

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}
export const highlightMail = (description) => {
  const email_regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  return description.replace(email_regex, '<a href="mailto:$1">$1</a>');
};

export const isLinkActive = (linkUrl, currentRoute) => {
  if (linkUrl === currentRoute) return true;
  if (linkUrl === "/") return false;
  return currentRoute.includes(linkUrl);
};

export const isProjectOwner = (
  project: ProjectHeaderType | undefined,
  userId: string
) => {
  return Boolean(project && userId === project.user.id);
};

export const isProjectMember = (
  project: ProjectHeaderType | undefined,
  userId: string
) => {
  const isMember =
    project &&
    project.members &&
    project.members.length &&
    project.members.find((member) => member.id === userId);
  return Boolean(isProjectOwner(project, userId) || isMember);
};

export const getFullName = (user: User) =>
  `${user.firstName} ${user.lastName}`.trim();

export const computePercentage = (numerator, denominator) =>
  Math.round((numerator / denominator) * 100);

export const searchTagsByQuery = (
  tags: TagType[],
  query: string = ""
): TagType[] =>
  tags.filter((tag) => {
    const isLabelMatching = tag.label
      .toLowerCase()
      .includes(query.toLowerCase());
    if (isLabelMatching) return true;

    const isCategoryMatching = tag.category
      .toLowerCase()
      .includes(query.toLowerCase());
    return isCategoryMatching;
  });

const STYLE_MARKUP_REPLACEMENT = "#escaped_style#";
const TARGET_STYLE_MARKUP = "style=";

export function encodeStyleMarkup(data: string): string {
  return data.replace(
    new RegExp(TARGET_STYLE_MARKUP, "g"),
    STYLE_MARKUP_REPLACEMENT
  );
}

export function removeEmptyPMarkup(text) {
  return text === "<p></p>\n" ? "" : text;
}

export function decodeStyleMarkup(data: string): string {
  return String(data).replace(
    new RegExp(STYLE_MARKUP_REPLACEMENT, "g"),
    TARGET_STYLE_MARKUP
  );
}

export function urlToFile(url: string): Promise<string> {
  // @ts-ignore
  return fetch(url)
    .then((r) => r.blob())
    .then(
      (blob) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function () {
            // @ts-ignore
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        })
    );
}

export function trimText(str: string = "", limit: number): string {
  return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
}

export function cssPlainToObject(plainCSS: string = ""): Object {
  const result = {};
  const attributes = plainCSS.split(";");

  for (let i = 0; i < attributes.length; i++) {
    const entry = attributes[i].split(/:(.+)/);
    let propertyName = entry.splice(0, 1)[0];
    const propertyNameParts = propertyName.split("-");
    propertyName = propertyNameParts
      .map((e, index) =>
        index === 0 ? e : e.charAt(0).toUpperCase() + e.slice(1)
      )
      .join("");
    result[propertyName] = entry.join("");
  }

  return result;
}

export function stripHTMLTags(html: string): string {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}

export function isEnded(date) {
  const now = new Date();
  const current = new Date(date);

  if (
    isSameToday(date) &&
    current.getUTCHours() === 0 &&
    current.getUTCMinutes() === 0
  ) {
    return false;
  }
  return new Date(date).getTime() < now.getTime();
}

export function isSameToday(date) {
  const current = new Date(date);
  const now = new Date();
  return (
    current.getUTCFullYear() === now.getUTCFullYear() &&
    current.getUTCMonth() === now.getUTCMonth() &&
    current.getUTCDate() === now.getUTCDate()
  );
}

export function getDateFormatForDateInput(stringDate?: string) {
  if (!stringDate) {
    return;
  }

  const date = parseDate(stringDate);
  const correctMonth = date.getUTCMonth() + 1;
  const month = correctMonth < 10 ? "0" + correctMonth : correctMonth;
  const day =
    date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  let zeroBlock = "";
  for (let i = 4 - date.getUTCFullYear().toString().length; i > 0; i--) {
    zeroBlock += "0";
  }

  return `${zeroBlock}${date.getUTCFullYear()}-${month}-${day}`;
}

export function getDateFormatForTimeInput(stringDate) {
  const date = parseDate(stringDate);
  const yearMonthDay = getDateFormatForDateInput(stringDate);

  const hours =
    date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
  const minutes =
    date.getUTCMinutes() < 10
      ? "0" + date.getUTCMinutes()
      : date.getUTCMinutes();

  return `${yearMonthDay}T${hours}:${minutes}`;
}

export function getTranslation(msgEn = "", msgFr = "", locale: string): string {
  const msg = locale === "fr" && msgFr !== "" ? msgFr || "" : msgEn || "";
  return msg;
}
