import { FunctionComponent, useCallback } from "react";
import { connect } from "redux-bundler-react";
import { Trans } from "@lingui/macro";
import { ButtonKind } from "../../../components/Button/Button";
import { SimpleModal } from "../../../components/Modal/SimpleModal";
import { ContentTypes } from "../../../types/inspirations";

interface DeleteModalWithoutConnectPropTypes {
  id: string;
  closeModal: () => void;
  isOpen: boolean;
  redirectOnDelete?: boolean;
}

interface DeleteModalPropTypes extends DeleteModalWithoutConnectPropTypes {
  doDeletePost?: ({ id: string }) => Promise<void>;
  doDeletePostSuccess?: ({ id: string }) => void;
  doUpdateRefreshCounter: ({ type: ContentTypes }) => void;
  doUpdateUrl: (url: string) => void;
}

export const PostDeleteModal: FunctionComponent<DeleteModalPropTypes> = ({
  id,
  closeModal,
  isOpen,
  doDeletePost,
  doDeletePostSuccess,
  doUpdateRefreshCounter,
  redirectOnDelete = false,
  doUpdateUrl
}) => {
  const deletePost = useCallback(
    () => (doDeletePost ? doDeletePost({ id }) : Promise.resolve()),
    [doDeletePost, id]
  );

  const onDeleteSuccess = useCallback(() => {
    if (doDeletePostSuccess) doDeletePostSuccess({ id });
    if (redirectOnDelete) doUpdateUrl(`/resources/articles`);
    doUpdateRefreshCounter({ type: ContentTypes.POST });
  }, [doDeletePostSuccess, doUpdateRefreshCounter, id, redirectOnDelete]);

  return (
    <SimpleModal
      onClick={deletePost}
      onClickSuccess={onDeleteSuccess}
      header={<Trans id="Delete post ?" component={null}>Delete post ?</Trans>}
      closeModal={closeModal}
      isOpen={isOpen}
      buttonKind={ButtonKind.error}
    />
  );
};

const connectedModal: FunctionComponent<
  DeleteModalWithoutConnectPropTypes
> = connect(
  "doDeletePost",
  "doDeletePostSuccess",
  "doUpdateRefreshCounter",
  "doUpdateUrl",
  PostDeleteModal
);

export default connectedModal;
