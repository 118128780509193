import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import routeNames from "../../consts/routeNames";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { ReactComponent as Logo } from "../../images/svg/icons-agora/logo.svg";
import { ReactComponent as ArrowRight } from "../../images/svg/icons/arrow-right.svg";
import { ReactComponent as Binoculars } from "../../images/svg/icons/binoculars.svg";
import { ReactComponent as AddButton } from "../../images/svg/icons/add-button.svg";
import { useDebounceValue } from "../../hooks/useDebounce";
import { RouteInfoType } from "../../types/routing";
import "./look4dataSearch.css";
import {
  SearchResultResponse,
  SearchResult
} from "../../types/look4dataSearch";
import Look4dataSearchCard from "./Look4dataSearchCard";
import Feedback from "./Feedback";
import { Pagination } from "../../components/Pagination/Pagination";
import { emptyPaginateResponse } from "../../types/pagination";
import { Loader } from "../../components/Loader/Loader";

interface Look4dataSearchPropTypes {
  queryObject: {
    query?: string;
    page?: string;
  };
  routeInfo: RouteInfoType;
  doFetchSearch: (params: {
    query: string;
    page?: string;
    abortControllerSignal?: AbortSignal;
  }) => Promise<SearchResultResponse>;
  doUpdateUrl: (newUrl: string) => void;
  doUpdateQuery: (queryParams: { [key: string]: string | undefined }) => void;
}

const Look4dataSearch: FunctionComponent<Look4dataSearchPropTypes> = ({
  queryObject,
  doFetchSearch,
  routeInfo,
  doUpdateUrl,
  doUpdateQuery
}) => {
  const [inputValue, setInputValue] = useState(queryObject.query || "");
  const [displayMoreResult, setDisplayMoreResult] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResultResponse>(
    emptyPaginateResponse
  );
  const debouncedValue = useDebounceValue(inputValue, 750);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isResultError, setIsResultError] = useState(false);
  const [
    abortController,
    setPreAbortController
  ] = useState<AbortController | null>(null);

  const retrieveAndProcessSearch = async () => {
    // cancel previous search request
    abortController && abortController.abort();

    if (debouncedValue) {
      const controller = new AbortController();
      setPreAbortController(controller);

      setIsSearchLoading(true);
      const query = debouncedValue;
      const { page } = queryObject;
      const { signal: abortControllerSignal } = controller;

      try {
        const searchResult = await doFetchSearch({
          query,
          page,
          abortControllerSignal
        });
        const {
          pagination: { totalResults }
        } = searchResult;

        setSearchResult(searchResult);
        setIsSearchLoading(false);
        setIsResultError(false);
        setDisplayMoreResult(Number(page) > 1 || totalResults < 6);
      } catch (err) {
        if (err instanceof Error && err.name !== "AbortError") {
          setIsSearchLoading(false);
        } else {
          setIsResultError(true);
        }
      }
    } else {
      setSearchResult(emptyPaginateResponse);
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      const uri = encodeURI(`${routeInfo.url}?query=${debouncedValue}`);
      doUpdateUrl(uri);
    } else {
      doUpdateUrl(`${routeInfo.url}`);
    }
  }, [debouncedValue]);

  useEffect(() => {
    retrieveAndProcessSearch();
  }, [queryObject]);

  const feedbackComponent = <Feedback searchQuery={debouncedValue} />;

  const buildCardGroup = (
    cards: SearchResult[],
    feedback = <></>,
    hasTop5 = false
  ) => {
    const searchCards = cards.map((card, idx) => (
      <Look4dataSearchCard
        key={card.level_0}
        rank={hasTop5 && idx < 5 ? idx + 1 : undefined}
        {...card}
      />
    ));

    return (
      <div
        className="mx-auto flex flex-wrap justify-center"
        style={{ gap: 20, marginTop: 20 }}
      >
        {searchCards}
        {feedback}
      </div>
    );
  };

  const moreResultsComponent = (
    <div className="result-separator w-full mt-10">
      <div className="line left-line" />
      <div
        className="uppercase flex items-center cursor-pointer"
        onClick={() => {
          setDisplayMoreResult(true);
        }}
      >
        <AddButton className="fill-current h-4" />
        <span className="mx-2">more results</span>
        <AddButton className="fill-current h-4" />
      </div>
      <div className="line right-line" />
    </div>
  );

  const buildSearchResults = (
    results: SearchResult[],
    moreResults: boolean,
    page: number
  ) => {
    const hasTop5 = page === 1;
    if (!moreResults) {
      const top5 = results.slice(0, 5);
      const top5Group = buildCardGroup(top5, feedbackComponent, hasTop5);

      if (results.length < 6) {
        return top5Group;
      }

      return (
        <>
          {top5Group}
          {moreResultsComponent}
        </>
      );
    }

    const firstCards = results.slice(0, 6);

    if (results.length < 6) {
      return buildCardGroup(firstCards, feedbackComponent, hasTop5);
    }

    const secondCards = results.slice(6);
    const firstGroup = buildCardGroup(firstCards, <></>, hasTop5);
    const secondGroup = buildCardGroup(secondCards, feedbackComponent);

    return (
      <>
        {firstGroup}
        {secondGroup}
      </>
    );
  };

  return (
    <>
      <div className="container mx-auto py-6 px-4">
        <a
          href={routeNames.agoraSearch}
          className="flex items-center justify-end"
          style={{ gap: 4 }}
        >
          <span className="uppercase text-sm font-bold text-gray-600">
            Search with
          </span>
          <Logo className="text-primary-10 fill-current h-5 w-5" />
          <span className="text-primary-10 font-bold"> Agora </span>
          <ArrowRight className="text-gray-800 fill-current h-5" />
        </a>

        <div className="flex items-center justify-center text-primary-10 mt-8">
          <Binoculars className="fill-current h-14 mr-2" />
          <span className="font-bold sm:text-3xl md:text-5xl">Look4Data</span>
          <label
            className="text-white self-start font-bold"
            style={{
              color: "white",
              backgroundColor: "#F36D50",
              padding: "2px 7px",
              borderRadius: "4px"
            }}
          >
            BETA
          </label>
        </div>

        <div
          className="text-center text-gray-700 md:text-base my-8 mx-auto md:px-20"
          style={{ width: "74%" }}
        >
          <p>
            Look4Data is an algorithm developed to search the most relevant
            content
          </p>
          <p>from various One Data Team resources.</p>
        </div>
        <div className="md:px-20">
          <SearchInput setInputValue={setInputValue} value={inputValue} />
        </div>

        {isSearchLoading ? (
          <div className="text-center mt-10">
            <Loader isLoading dark />
          </div>
        ) : isResultError ? (
          <div className="text-center mt-10">
            <ErrorMessage message={"An error has occured"} />
          </div>
        ) : (
          <>
            {debouncedValue &&
              searchResult &&
              searchResult.data &&
              buildSearchResults(
                searchResult.data,
                displayMoreResult,
                searchResult.pagination.currentPage
              )}
            {debouncedValue && displayMoreResult && (
              <Pagination
                {...searchResult.pagination}
                updateQueryParams={doUpdateQuery}
                currentQueryParams={queryObject}
              />
            )}
          </>
        )}
        {debouncedValue &&
          !isSearchLoading &&
          !isResultError &&
          searchResult.data.length === 0 && (
            <div className="text-center mt-10">
              <span>There is no result for this search</span>
            </div>
          )}
      </div>
    </>
  );
};

export default connect(
  "selectQueryObject",
  "selectRouteInfo",
  "doFetchSearch",
  "doUpdateUrl",
  "doUpdateQuery",
  Look4dataSearch
);
