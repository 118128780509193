export const initialState = {
  events: []
};
const events = {
  name: "events",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case "GET_EVENTS_SUCCESS":
          return {
            ...state,
            events: payload.events
          };
        case "POST_EVENT_SUCCESS":
          return {
            ...state,
            events: [payload.newEvent].concat(state.events)
          };
        default:
          return state;
      }
    };
  },

  doFetchEventsFromToday: () => ({ dispatch, apiFetch }) =>
    apiFetch(`admin/events?fromToday=1&limit=5`),

  doFetchEventsByDate: (month, year, noDispatch = false) => ({
    dispatch,
    apiFetch
  }) => {
    return apiFetch(`admin/events?month=${month}&year=${year}`).then(events => {
      if (noDispatch) {
        return events;
      }
      dispatch({
        type: "GET_EVENTS_SUCCESS",
        payload: { events }
      });
    });
  },

  doFetchEvents: ({ query = "", page = 1, limit = 6 }) => ({
    dispatch,
    apiFetch
  }) => {
    return apiFetch(
      `admin/events?query=${encodeURI(query)}&page=${page}&limit=${limit}`
    );
  },

  doFetchEvent: eventId => ({ store, apiFetch }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`admin/events/${eventId || routeParams.id}`);
  },

  doFetchEventById: id => ({ apiFetch }) => {
    return apiFetch(`admin/events/${id}`);
  },

  doPostEvent: newEvent => ({ apiFetch, dispatch, store }) =>
    apiFetch("admin/events", {
      method: "POST",
      body: newEvent
    }).then(event => {
      const { id } = event;
      dispatch({
        type: "POST_EVENT_SUCCESS",
        payload: { newEvent: event }
      });
      store.doUpdateUrl(`/events/${id}`);
    }),

  doUpdateEvent: event => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`admin/events/${routeParams.id}`, {
      method: "PUT",
      body: event
    }).then(() => store.doUpdateUrl(`/admin/events`));
  },

  doDeleteEvent: eventId => ({ apiFetch }) =>
    apiFetch(`admin/events/${eventId}`, {
      method: "DELETE"
    }),

  selectEvents: state => state.events.events
}
export default events;
