export const initialState = {};
const projectsTab = {
  name: "projectsTabs",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchProjectGeneral: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/general`);
  },
  doUpdateProjectGeneral: ({
    elevatorPitch,
    goodToKnow,
    objective,
    prerequisites,
    benefits,
    business_value,
    risks,
    implementationEfforts,
    other,
    links
  }) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/general`, {
      method: "PUT",
      body: {
        elevatorPitch,
        goodToKnow,
        objective,
        prerequisites,
        benefits,
        business_value: {
          scoping: String(business_value.scoping),
          pilot: String(business_value.pilot),
          industrialization: String(business_value.industrialization)
        },
        risks,
        implementationEfforts,
        other,
        links
      }
    });
  },
  doFetchProjectDataScience: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/datascience`);
  },
  doFetchProjectIntegrationPattern: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/integrationpattern`);
  },
  doFetchProjectEcosystem: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/ecosystem`);
  },
  doUpdateProjectEcosystem: ecoSystem => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/ecosystem`, {
      method: "POST",
      body: ecoSystem
    });
  },
  doUpdateProjectDataScience: ({
    id,
    introduction,
    dataTypes,
    dataDescription,
    dataVolume,
    dataSource,
    velocityOfUpdate,
    languages,
    standardLibraries,
    statisticalIndividuals,
    machineLearningProblemType,
    target,
    targetChallenges,
    algorithm,
    metric,
    audience,
    objectives,
    datascienceBenefits,
    painPoints,
    run,
    modelRetrain,
    steps,
    results,
    datascienceOther,
    cloudFormation = "",
    integratedToCDH,
    cdhHelp,
    pattern,
    useMachineLearning,
    useDataViz
  }) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/datascience`, {
      method: "PUT",
      body: {
        id,
        introduction,
        dataTypes: dataTypes.map(tag => tag.id),
        dataDescription,
        dataVolume,
        dataSource,
        cloudFormation,
        velocityOfUpdate,
        languages,
        standardLibraries,
        statisticalIndividuals,
        machineLearningProblemType,
        target,
        targetChallenges,
        algorithm,
        metric,
        audience,
        objectives,
        datascienceBenefits,
        painPoints,
        run,
        modelRetrain,
        steps,
        results,
        datascienceOther,
        integratedToCDH,
        cdhHelp,
        pattern,
        useMachineLearning,
        useDataViz
      }
    });
  },
  doFetchProjectComments: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/comments`);
  },
  doPostProjectComment: comment => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`projects/${routeParams.id}/comments`, {
      method: "POST",
      body: {
        comment
      }
    });
  },
  doDeleteProjectComment: comment => ({ apiFetch }) =>
    apiFetch(`projects/comments/${comment.id}`, {
      method: "DELETE"
    })
}
export default projectsTab;
