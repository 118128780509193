import { FunctionComponent, SVGProps } from "react";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import { connect } from "redux-bundler-react";
import { HeaderLink } from "./HeaderLink/HeaderLink";
import { ReactComponent as IconSearch } from "../../images/svg/icons-agora/access.svg";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import { AgoraLogo } from "./AgoraLogo/AgoraLogo";
import { RouteInfoType } from "../../types/routing";
import HeaderNotif from "./HeaderNotif/HeaderNotif";
import routeNames from "../../consts/routeNames";

export interface navItemType {
  url: string;
  label?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

interface PropTypes {
  routeInfo: RouteInfoType;
  isAdmin: boolean;
}

const navItems: navItemType[] = [
  { url: "/dataatengie", label: "One Data Team" },
  { url: "/projects", label: "Use cases" },
  { url: "/inspirations", label: "Inspirations" },
  { url: "/data4u", label: "Data4U" },
  { url: "/resources", label: "Resources" },
  { url: "/events/list", label: "Events" },
  { url: "/directory", label: "Community" },
  { url: "/powerbi", label: "Dashboard" }
];

const getItems = isAdmin => {
  return isAdmin ? [...navItems, { url: "/admin", label: "Admin" }] : navItems;
};

export const HeaderMenu: FunctionComponent<PropTypes> = ({
  routeInfo,
  isAdmin
}) => {
  return (
    <nav className="bg-primary-10 text-neutral-1 px-6 z-10">
      <ul className="flex items-center justify-center">
        <li className="mr-auto font-roboto font-hairline text-3xl hidden sm:block">
          <AgoraLogo />
        </li>

        <li className="z-50 block lg:hidden">
          <DropdownMenu navItems={getItems(isAdmin)} />
        </li>

        {getItems(isAdmin).map((item, index) => (
          <HeaderLink
            key={index}
            item={item}
            currentRoute={routeInfo.pattern}
          />
        ))}

        <li className="ml-auto z-50">
          <HeaderNotif />
        </li>
        <li className="ml-4 z-50">
          <a
            href={routeNames.searchNav}
            className="flex items-center justify-center"
          >
            <IconSearch className="h-6 fill-current mr-1 hidden lg:block" />
          </a>
        </li>
        <li className="ml-4 z-50">
          <HeaderProfile />
        </li>
      </ul>
      {/*<span className="flex items-center justify-center mt-2 mb-2">
        We’re currently implementing the new organization of ENGIE in Agora. The
        BUs and BEs will soon be replaced in all pages.
        </span>*/}
    </nav>
  );
};

export default connect(
  "selectRouteInfo",
  HeaderMenu
);
