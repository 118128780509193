import { User } from "./user";
import { TagType } from "./tag";
import { PaginatedResponse } from "./pagination";

export interface Post {
  id?: string;
  creationDate: string;
  text?: string;
  url?: string;
  image?: string;
  image_in_s3?: string;
  title?: string;
  description?: string;
  user: User;
  tags?: TagType[];
  type: FeedItemPost;
  isBookMarked?: boolean;
}

export enum FeedItemPost {
  POST = "post",
  PROJECT = "project"
}

export interface PostResponse extends PaginatedResponse {
  data: Post[];
}
