import { TagType } from "../../types/tag";

export const emptyTag: TagType = {
  id: 0,
  label: "",
  category: ""
};

export const tags: TagType[] = [
  {
    id: 1,
    label: "tag 1",
    category: "category a"
  },
  {
    id: 2,
    label: "tag 2",
    category: "category b"
  },
  {
    id: 3,
    label: "tag 3",
    category: "category b"
  },
  {
    id: 4,
    label: "tag 4",
    category: "category b"
  },
  {
    id: 5,
    label: "tag 5",
    category: "category c"
  }
];

export const BUTags: TagType[] = [
  {
    id: 101,
    label: "BU1",
    category: "BU"
  },
  {
    id: 102,
    label: "BU2",
    category: "BU"
  }
];

export const BETags: TagType[] = [
  {
    id: 121,
    label: "BE1",
    category: "BE"
  }
];
