import { Fragment, FunctionComponent, useState } from "react";
import { ReactComponent as Placeholder } from "../../images/svg/icons/icon-photo.svg";
import classNames from "classnames";
import { Loader } from "../Loader/Loader";

export interface CardArticlePropTypes {
  articlePicture: { src?: string; alt?: string };
  articleTitle?: string;
  articlePageUrl: string;
  articleExternalLink?: string;
  isSmall?: boolean;
}

export const CardArticle: FunctionComponent<CardArticlePropTypes> = ({
  articlePicture,
  articleTitle,
  articlePageUrl,
  articleExternalLink,
  isSmall
}) => {
  const [retry, setRetry] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);

  const articleClasses = classNames("overflow-hidden mb-3", {
    "px-6": !isSmall,
    "px-4": isSmall
  });

  const imageSize = isSmall ? "h-48" : "h-48 sm:h-64";

  const titleClasses = classNames("font-bold mb-2", {
    "text-xl": !isSmall,
    "text-base": isSmall
  });

  function onError() {
    setRetryCount(retryCount + 1);
    setIsLoading(true);
    setTimeout(() => {
      setRetry(`${articlePicture.src}?retry`);
      setIsLoading(false);
    }, 2000);
  }

  return articleExternalLink ? (
    <Fragment>
      <a
        href={articlePageUrl}
        className={classNames({ "disable-click": articlePageUrl === "#" })}
      >
        {articlePicture.src && retryCount < 2 ? (
          <Fragment>
            {isLoading ? (
              <div className="p-6 justify-center items-center flex">
                <Loader isLoading dark />
              </div>
            ) : (
              <img
                className={`bg-cover bg-center ${imageSize} w-full mb-4`}
                src={retry || articlePicture.src}
                onError={onError}
                alt={articleTitle}
              />
            )}
          </Fragment>
        ) : (
          <Placeholder className={`w-full ${imageSize} default-svg`} />
        )}
      </a>
      {articleTitle ? (
        <div className={articleClasses}>
          <div className={titleClasses}>
            <a
              href={articlePageUrl}
              className={classNames("block w-full", {
                "disable-click": articlePageUrl === "#"
              })}
            >
              {articleTitle}
            </a>
          </div>
        </div>
      ) : null}
    </Fragment>
  ) : null;
};
