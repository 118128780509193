import { createSelector } from "redux-bundler";
import {
  storeTokens,
  getToken,
  isTokenInURL,
  redirectToOktaLogin,
  logout
} from "../services/authService";

export const reactIsLoggedInFunction = (isOktaLoggedIn, id) => {
  if (isOktaLoggedIn === false) return { actionCreator: "doLogin" };
  if (isOktaLoggedIn && id === "") {
    return { actionCreator: "doUpdateUserInfo" };
  }
};

export const initialState = {
  isOktaLoggedIn: null,
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  accessToken: "",
  lang: "en",
  isAdmin: false,
  bus: []
};

const user = {
  name: "user",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case "LOGIN_SUCCESS":
          return {
            ...state,
            isOktaLoggedIn: true,
            accessToken: payload.accessToken
          };
        case "NOT_LOGGED_IN":
          return { ...state, isOktaLoggedIn: false };
        case "UPDATE_USER_INFO":
          const {
            id,
            firstName,
            lastName,
            email,
            language,
            isAdmin,
            bus,
            lastConnexion
          } = payload;
          return {
            ...state,
            id,
            firstName,
            lastName,
            email,
            lang: language,
            isAdmin,
            bus,
            lastConnexion
          };
        case "UPDATE_USER_LANG":
          const { lang } = payload;
          return { ...state, lang };
        default:
          return state;
      }
    };
  },

  doLogin: () => () => {
    redirectToOktaLogin();
  },
  doLogout: () => ({ dispatch }) =>
    logout().then(() => dispatch({ type: "NOT_LOGGED_IN" })),
  doCheckIsAuthenticated: () => ({ dispatch }) => {
    if (isTokenInURL()) {
      return storeTokens()
        .then(() => getToken("accessToken"))
        .then(({ accessToken }) =>
          dispatch({ type: "LOGIN_SUCCESS", payload: { accessToken } })
        )
        .catch(() => dispatch({ type: "NOT_LOGGED_IN" }));
    } else {
      return getToken("accessToken")
        .then(({ accessToken }) =>
          accessToken
            ? dispatch({ type: "LOGIN_SUCCESS", payload: { accessToken } })
            : dispatch({ type: "NOT_LOGGED_IN" })
        )
        .catch(() => dispatch({ type: "NOT_LOGGED_IN" }));
    }
  },
  doUpdateUserInfo: () => ({ dispatch, apiFetch }) =>
    apiFetch("users/profile").then(
      ({
        id,
        firstName,
        lastName,
        email,
        language,
        isAdmin,
        bus,
        be,
        lastConnexion
      }) => {
        if (bus && bus.length) {
          //window.userBU = bus[0].label;
          //gtag("event", "bu_dimension", { BU: bus[0].label }); // eslint-disable-line
        }
        if (be && be.length) {
          //window.userBE = be[0].label;
          //gtag("event", "be_dimension", { BE: be[0].label }); // eslint-disable-line
        }
        return dispatch({
          type: "UPDATE_USER_INFO",
          payload: {
            id,
            firstName,
            lastName,
            email,
            language,
            isAdmin,
            bus,
            lastConnexion
          }
        });
      }
    ),
  doUpdateUserLang: lang => ({ dispatch, apiFetch, store }) => {
    apiFetch(`users/${store.selectUserId()}`, {
      method: "PATCH",
      body: { language: lang }
    }).then(() => {
      dispatch({ type: "UPDATE_USER_LANG", payload: { lang } });
    });
  },

  doFetchProfile: userId => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${userId ? userId : routeParams.id}`);
  },

  doDeleteUser: user => ({ apiFetch, store }) => {
    return apiFetch(`users/${user.id}`, {
      method: "DELETE"
    }).then(() => {
      store.doUpdateUrl("/");
    });
  },

  doUpdateProfile: ({
    id,
    firstName,
    lastName,
    email,
    description,
    tags,
    bus,
    bes,
    cdoOf
  }) => ({ dispatch, apiFetch, store }) => {
    const currentUserID = store.selectUserId();
    return apiFetch(`users/${id}`, {
      method: "PATCH",
      body: { firstName, lastName, email, description, tags, bus, bes, cdoOf }
    }).then(profile => {
      if (currentUserID === profile.id) {
        dispatch({ type: "UPDATE_USER_INFO", payload: profile });
      }
      return profile;
    });
  },

  doFetchProjectsBookmark: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/bookmark/projects/header`);
  },

  doFetchInitiativesBookmark: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/bookmark/initiatives/header`);
  },

  doFetchPostsBookmark: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/bookmark/posts`);
  },

  doFetchProjectsContributions: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/projects/header`);
  },

  doFetchUserProjects: () => ({ apiFetch, store }) => {
    const userId = store.selectUserId();
    return apiFetch(`users/${userId}/projects/header`);
  },

  doFetchPostsContributions: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/posts`);
  },
  doFetchUserNotifications: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}/settings`);
  },
  doFetchUserProfile: () => ({ apiFetch }) => {
    return apiFetch(`users/profile`);
  },
  doPostUserNotifications: notifications => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users/${routeParams.id}`, {
      method: "PATCH",
      body: notifications
    });
  },

  selectIsOktaLoggedIn: state => state.user.isOktaLoggedIn,
  selectIsLoggedIn: state => Boolean(state.user.id),
  selectUserName: state =>
    state.user.firstName && state.user.lastName
      ? `${state.user.firstName} ${state.user.lastName[0]}.`
      : "",
  selectAccessToken: state => state.user.accessToken,
  selectUserLang: state => state.user.lang,
  selectUserId: state => state.user.id,
  selectUser: state => state.user,
  selectIsUserAdmin: state => state.user.isAdmin,
  selectLastConnexion: state => state.user.lastConnexion,

  reactIsLoggedIn: createSelector(
    "selectIsOktaLoggedIn",
    "selectUserId",
    reactIsLoggedInFunction
  ),

  init: store => store.doCheckIsAuthenticated()
}
export default user;
