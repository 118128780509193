import { FC, PropsWithChildren, } from "react";

interface ActionElementPropTypes {
  onClick?: () => void;
  textColor?: string;
}

export const ActionElement: FC<PropsWithChildren<ActionElementPropTypes>> = ({
  onClick,
  children,
  textColor = "text-info-7"
}) => (
  <button onClick={onClick} className="w-full py-2 px-4">
    <span className={`flex items-center whitespace-no-wrap ${textColor}`}>
      {children}
    </span>
  </button>
);
