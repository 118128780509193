import { useState } from "react";

export function useToggle(initialIsVisible): [boolean, () => void] {
  const [state, setState] = useState<boolean>(initialIsVisible);

  const toggleState = () => {
    setState(oldState => !oldState);
  };

  return [state, toggleState];
}
