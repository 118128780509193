import { FunctionComponent } from "react";
import { Avatar } from "../Avatar/Avatar";
import { Trans } from "@lingui/macro";
import { CustomDate } from "./CustomDate";
import CardActions from "../../modules/CardActions/CardActions";
import { ContentTypes } from "../../types/inspirations";
import { User } from "../../types/user";
import { getFullName } from "../../services/utils";

interface HeaderPropTypes {
  postId?: string;
  newsDate: Date;
  canBeDeleted: boolean;
  canBeUnlinked: boolean;
  user: User;
}

export const Header: FunctionComponent<HeaderPropTypes> = ({
  postId,
  user,
  newsDate,
  canBeDeleted,
  canBeUnlinked
}) => (
  <div className="px-6 py-4 flex justify-between text-sm items-center mt-1">
    <div className="flex items-center">
      <Avatar user={user} />
      <div className="ml-2">
        <p>
          <span className={"font-bold"}>
            <a href={`/profile/${user.id}`}>{getFullName(user)}</a>
          </span>{" "}
          <Trans id="shared" component={null}>shared</Trans>
        </p>
        <p className="text-neutral-6 text-xs mt-1">
          <CustomDate date={newsDate} />
        </p>
      </div>
    </div>
    {postId ? (
      <div className="flex flex-col items-end">
        <CardActions
          cardType={ContentTypes.POST}
          id={postId}
          canBeDeleted={canBeDeleted}
          canBeUnlinked={canBeUnlinked}
        />
      </div>
    ) : null}
  </div>
);
