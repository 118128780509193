import { FunctionComponent } from "react";
import classnames from "classnames";

interface PropsTypes {
  title: string;
  icon?: any;
  highlight?: string;
  color?: string;
  fontSize?: string;
  uppercase?: boolean;
}

const UnderlineTitle: FunctionComponent<PropsTypes> = ({
  title,
  icon,
  highlight = "",
  color = "bg-blue-700",
  fontSize = "text-3xl",
  uppercase
}) => {
  if (title === "" && (!highlight || highlight === "")) {
    return null;
  }
  return (
    <div className={fontSize}>
      <div
        className={classnames("flex items-center", { uppercase: uppercase })}
      >
        {!!icon && icon}
        {highlight !== "" ? (
          <>
            <span className="text-blue-700 font-extrabold">
              {highlight}&nbsp;
            </span>
            {title}
          </>
        ) : (
          `${title || ""}`
        )}
      </div>
      <div
        className={classnames("mt-1", color)}
        style={{
          width: 50,
          height: 5
        }}
      />
    </div>
  );
};

export default UnderlineTitle;
