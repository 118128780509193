import { FC, Fragment, useState } from "react";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { ReactComponent as Dots } from "../../images/svg/icons/icon-dots-horizontal.svg";
import { LinkActionElement } from "./ActionElement/LinkActionElement";
import { ActionElement } from "./ActionElement/ActionElement";
import { DeleteActionElement } from "./ActionElement/DeleteActionElement";
import { UnlinkActionElement } from "./ActionElement/UnlinkActionElement";
import { PostModals } from "../../types/modalsType";
import { ContentTypes } from "../../types/inspirations";
import DeleteModal from "./ActionModals/PostDeleteModal";
import UnlinkInspirationModal from "./ActionModals/UnlinkInspirationModal";
import InspirationModal from "./ActionModals/InspirationModal";
import ShareActionElement from "./ActionElement/ShareActionElement";
import { getShareMailToLink } from "./ActionElement/utils";
import { trackEventShare } from "../../services/analytics";

interface CardActionsPropTypes {
  cardType: ContentTypes;
  id: string;
  canBeDeleted?: boolean;
  canBeUnlinked?: boolean;
  isUserAdmin?: boolean;
}

export const CardActions: FC<CardActionsPropTypes> = ({
  cardType,
  id,
  canBeDeleted = false,
  canBeUnlinked = false,
  isUserAdmin = false
}) => {
  const [activeModal, setActiveModal] = useState<PostModals | "">("");

  function closeModal() {
    setActiveModal("");
  }

  function openInspirationModal() {
    setActiveModal(PostModals.INSPIRATION_LINK);
  }

  function openDeleteModal() {
    setActiveModal(PostModals.DELETE_POST);
  }

  function openUnlinkModal() {
    setActiveModal(PostModals.INSPIRATION_UNLINK);
  }

  function shareClick(contentType, id) {
    const shareMailToLink = getShareMailToLink(contentType, id);
    window.location.assign(shareMailToLink);
    trackEventShare(contentType, shareMailToLink);
  }

  const canBeLinked = isUserAdmin && cardType === ContentTypes.INITIATIVE;
  const canBeShared = !!id;

  const hasMinOneAction =
    canBeShared || canBeLinked || canBeUnlinked || canBeDeleted;

  return hasMinOneAction ? (
    <Fragment>
      {canBeLinked && (
        <InspirationModal
          id={id}
          cardType={cardType}
          closeModal={closeModal}
          isOpen={activeModal === PostModals.INSPIRATION_LINK}
        />
      )}
      {canBeUnlinked && (
        <UnlinkInspirationModal
          id={id}
          closeModal={closeModal}
          isOpen={activeModal === PostModals.INSPIRATION_UNLINK}
          type={cardType}
        />
      )}
      {canBeDeleted && (
        <DeleteModal
          id={id}
          closeModal={closeModal}
          isOpen={activeModal === PostModals.DELETE_POST}
        />
      )}
      <div className="relative">
        <div className="absolute bg-neutral-0 rounded-full inset-0 shadow opacity-75" />
        <Dropdown
          buttonLabel={
            <div className="h-8 w-8">
              <Dots className="fill-current h-8 text-info-7" />
            </div>
          }
          withTriangle={false}
        >
          <ul className="bg-neutral-0 shadow border border-neutral-2 rounded mt-1">
            {canBeShared && (
              <li className="border-b border-neutral-2 -mb-px hover:bg-neutral-2 cursor-pointer">
                <ActionElement
                  onClick={() => {
                    shareClick(cardType, id);
                  }}
                >
                  <ShareActionElement id={id} contentType={cardType} />
                </ActionElement>
              </li>
            )}
            {canBeLinked && (
              <li className="border-b border-neutral-2 -mb-px hover:bg-neutral-2 cursor-pointer">
                <ActionElement onClick={openInspirationModal}>
                  <LinkActionElement />
                </ActionElement>
              </li>
            )}
            {canBeUnlinked && (
              <li className="border-b border-neutral-2 -mb-px hover:bg-neutral-2 cursor-pointer">
                <ActionElement
                  onClick={openUnlinkModal}
                  textColor="text-warning-7"
                >
                  <UnlinkActionElement />
                </ActionElement>
              </li>
            )}
            {canBeDeleted && (
              <li className="border-b border-neutral-2 -mb-px hover:bg-neutral-2 cursor-pointer">
                <ActionElement
                  onClick={openDeleteModal}
                  textColor="text-error-7"
                >
                  <DeleteActionElement />
                </ActionElement>
              </li>
            )}
          </ul>
        </Dropdown>
      </div>
    </Fragment>
  ) : null;
};

export default CardActions;
