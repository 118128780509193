import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { ReactComponent as IconStar } from '../../images/svg/icons-agora/bookmark.svg';
import { ReactComponent as Placeholder } from '../../images/svg/icons/icon-photo.svg';
import CardActions from '../../modules/CardActions/CardActions';
import { ContentTypes } from '../../types/inspirations';
import { ProjectHeaderType } from '../../types/project';
import { BookmarkStar } from '../BookmarkStar/BookmarkStar';
import { CardTag } from '../CardTag/CardTag';
import { FillingProgress } from '../FillingProgress/FillingProgress';
import { LanguageBox } from '../LanguageBox/LanguageBox';
import ProjectStatus from '../ProjectStatus/ProjectStatus';
import { Contact } from './Contact';

// import { TagLink } from "../../types/tag";

interface technology {
  name: string;
  active: boolean;
  other: boolean;
}
interface sectionItem {
  active: boolean;
  technologies: technology[];
}

interface jsonsection {
  [k: string]: sectionItem;
}
interface ProjectCardPropTypes extends ProjectHeaderType {
  isBig?: boolean;
  onBookmarkClick?: (content: {
    contentId: string;
    type: ContentTypes;
    isBookmarked: boolean;
  }) => Promise<{}>;
  canBeUnlinked?: boolean;
  useCardActions?: boolean;
  isFluid?: boolean;
  fileImage: string;
}

export const ProjectCard: FunctionComponent<ProjectCardPropTypes> = ({
  contactPoint,
  image,
  title,
  status,
  tags,
  isBig = false,
  id,
  isBookMarked,
  onBookmarkClick = () => Promise.resolve({}),
  canBeUnlinked = false,
  bookMarks,
  useCardActions = true,
  fillingRate,
  isFluid = false,
  bus,
  bes,
  data_management,
  data_journey,
  common_api,
  uctype,
  language,
  fileImage
}) => {
  function onBookmarkProject(bookmarkState) {
    return onBookmarkClick({
      contentId: id,
      type: ContentTypes.PROJECT,
      isBookmarked: bookmarkState
    });
  }

  function extractActiveTechnologies(
    data_management: jsonsection,
    data_journey: jsonsection,
    common_api: { [k: string]: { active: boolean; other: boolean } }
  ): string[] {
    const json = { ...data_management, ...data_journey };
    let first_part = Object.values(json).map(section => {
      let onlyActive = section.technologies.filter(
        technology =>
          technology.active && !technology.other && technology.name !== ""
      );

      let list = onlyActive.map(tech => tech.name);
      return list;
    });

    let res = [
      ...first_part,
      ...(Object.values(common_api).filter(item => item.active).length > 0
        ? ["commonAPI"]
        : [])
    ];
    //@ts-ignore
    return [].concat.apply([], res);
  }
  
  const technologies: string[] = extractActiveTechnologies(
    JSON.parse(data_management),
    JSON.parse(data_journey),
    JSON.parse(common_api)
  );
  const technologiesMaxLength: number = 2;
  // 
  return (
    <div
      className={classNames("relative", {
        "w-80": !isFluid,
        "sm:w-112 bg-neutral-0 rounded overflow-hidden shadow-md my-1": isBig,
        "card-project": !isBig
      })}
      data-cy-card
    >
      {useCardActions && (
        <div className="absolute right-0 top-0 mt-2 mr-2">
          <CardActions
            cardType={ContentTypes.PROJECT}
            id={id}
            canBeUnlinked={canBeUnlinked}
          />
        </div>
      )}
      <BookmarkStar selected={isBookMarked} onClick={onBookmarkProject} />
      <div className="absolute right-0 top-0 mt-2 mr-12">
        <LanguageBox language={language} />
      </div>
      <a href={`/projects/${id}`}>
        {image.url ? (
          <div
            className="bg-cover bg-center h-64 w-full mb-4"
            style={{ backgroundImage: `url(/${fileImage}` }}
          />
        ) : (
          <Placeholder className="w-full h-64 mb-4 default-svg block" />
        )}
      </a>
      <div className="mx-6 mb-4">
        <div className="flex items-center mb-2 text-neutral-6">
          <Contact user={contactPoint} />
        </div>
        <div className="flex capitalize items-center mb-2 text-blue-500">
          {uctype.length ? uctype : <>&nbsp;</>}
        </div>
        <a href={`/projects/${id}`}>
          <div
            className="h-16 overflow-hidden long-text text-neutral-8"
            title={title}
            data-cy-card-title
          >
            {title}
          </div>
        </a>
        <ProjectStatus value={status} readonly />
        <div className="flex flex-col h-32 mt-4">
          <div className="flex flex-wrap overflow-hidden items-center max-h-16">
            {bus.map(bu => (
              <CardTag isPrimary key={bu.id} tag={bu} />
            ))}
            {bes.map(be => (
              <CardTag isPrimary key={be.id} tag={be} />
            ))}
          </div>
          <div className="flex flex-wrap overflow-hidden items-center max-h-16">
            {tags.map(tag => (
              <CardTag key={tag.id} tag={tag} />
            ))}
          </div>
          <div
            className="flex flex-wrap overflow-hidden items-center max-h-16"
            hidden={!technologies.length}
          >
            {technologies.slice(0, technologiesMaxLength).map(technology => (
              <span
                key={technology}
                className="inline-block capitalize rounded-full hover:bg-neutral-5 hover:text-neutral-1 px-3 py-1 text-sm font-semibold mr-2 mb-2 text-sm bg-teal-500 text-neutral-0"
              >
                {technology}
              </span>
            ))}
            {technologies.length > technologiesMaxLength && (
              <span
                key={"rest-of-technologies"}
                className="inline-block capitalize rounded-full hover:bg-neutral-5 hover:text-neutral-1 px-2 py-1 text-sm font-semibold mb-2 text-sm bg-teal-500 text-neutral-0"
              >
                {technologies.length > technologiesMaxLength &&
                  `+${technologies.length - technologiesMaxLength}`}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-between mb-4">
          <div className="flex items-center">
            <span className="text-neutral-4">
              <Trans id="More Videos" component={null}>Filled</Trans>
              {":"}
            </span>
            <FillingProgress progress={fillingRate} radius={20} stroke={4} />
          </div>
          <div className="flex items-center">
            <IconStar className="text-neutral-4 fill-current h-5 w-auto mr-2" />
            <span className="text-neutral-4 font-medium">{bookMarks}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
