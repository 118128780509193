import { FunctionComponent, useState } from "react";
import { useComponentVisible } from "../../hooks/UseIsComponentVisible";
import { Input } from "../Input/Input";

export interface InputDropdownPropTypes {
  renderItems: (input: string) => JSX.Element[];
  placeholder: string;
  label?: string;
  id: string;
  disabled?: boolean;
}

export const InputDropdown: FunctionComponent<InputDropdownPropTypes> = ({
  renderItems,
  placeholder,
  label,
  id,
  disabled = false
}) => {
  const [inputValue, setInputValue] = useState("");
  function onInputChange({ target: { value } }) {
    setIsComponentVisible(true);
    setInputValue(value);
  }

  function clearInput() {
    setInputValue("");
  }

  function mouseLeave() {
    setIsComponentVisible(false);
  }

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  function onInputFocus() {
    setIsComponentVisible(true);
  }

  return (
    <div className="relative" ref={ref}>
      <Input
        placeholder={placeholder}
        onChange={onInputChange}
        onFocus={onInputFocus}
        value={inputValue}
        id={id}
        label={label}
        isSearchInput
        disabled={disabled}
      />
      {isComponentVisible ? (
        <ul
          className="z-10 absolute left-0 right-0 max-h-xxs overflow-auto border-b border-neutral-3"
          onClick={clearInput}
          onMouseLeave={mouseLeave}
        >
          {renderItems(inputValue)}
        </ul>
      ) : null}
    </div>
  );
};
