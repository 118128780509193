const members = {
  name: "members",
  getReducer: () => {
    const initialState = {
      members: [],
      pagination: void 0
    };
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case "GET_MEMBERS_SUCCESS":
          const { members, pagination } = payload;
          return { ...state, members, pagination };
        default:
          return state;
      }
    };
  },

  doFetchMembers: ({
    query = "",
    bus = "",
    bes = "",
    role = "",
    page = 1
  }) => ({ dispatch, apiFetch }) => {
    return apiFetch(
      `users?query=${query}&bus=${bus}&bes=${bes}&role=${role}&page=${page}&limit=12`
    ).then(response =>
      dispatch({
        type: "GET_MEMBERS_SUCCESS",
        payload: { members: response.data, pagination: response.pagination }
      })
    );
  },
  doFetchAllMembers: () => ({ dispatch, apiFetch }) => {
    return apiFetch(`users`).then(response =>
      dispatch({
        type: "GET_MEMBERS_SUCCESS",
        payload: { members: response.data, pagination: response.pagination }
      })
    );
  },
  selectMembers: state => state.members.members,
  selectMembersPagination: state => state.members.pagination
};

export default members
