import { FunctionComponent } from "react";
import {
  getColorWithInitial,
  getFullName,
  getInitials
} from "../../services/utils";
import { User } from "../../types/user";

export interface AvatarPropTypes {
  user: User;
  profilePicture?: string;
  small?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
  linkToProfile?: boolean;
}

export const Avatar: FunctionComponent<AvatarPropTypes> = ({
  user,
  profilePicture,
  small = false,
  xsmall = false,
  xxsmall = false,
  linkToProfile = true
}) => {
  let size;
  if (xxsmall) {
    size = "w-6 h-6 text-sm";
  } else if (xsmall) {
    size = "w-8 h-8 text-base";
  } else if (small) {
    size = "w-10 h-10 text-lg";
  } else {
    size = "w-12 h-12 text-lg";
  }

  const href = linkToProfile ? `/profile/${user.id}` : "";
  const userName = getFullName(user);
  const initials = getInitials(userName);
  const color = getColorWithInitial(initials);
  return (
    <a href={href}>
      {profilePicture ? (
        <img
          className={`rounded-full ${size}`}
          src={profilePicture}
          alt={userName}
        />
      ) : (
        <div
          className={`notranslate rounded-full ${size} flex items-center justify-center text-neutral-1 z-10`}
          style={{ backgroundColor: color }}
          title={userName}
          data-test-avatar
        >
          {initials}
        </div>
      )}
    </a>
  );
};
