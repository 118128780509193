import { FunctionComponent } from "react";
import { ReactComponent as Facebook } from "../../images/svg/logos/facebook-f.svg";
import { ReactComponent as Instagram } from "../../images/svg/logos/instagram.svg";
import { ReactComponent as Twitter } from "../../images/svg/logos/twitter.svg";
import OneDataTeamLogo from "../../images/png/onedatateam.png"
import { Trans } from "@lingui/macro";

export const Footer: FunctionComponent<{}> = () => (
  <div className="footer text-center mt-10">
    <div className="color-line bg-footer">

      <div className="pt-4">
        <img {...{
          alt: "One Data Team logo",
          src: OneDataTeamLogo,
          style: {
            height: "2.5rem",
            margin: 'auto'
          }
        }} />
      </div>
    </div>
    <ul className="flex flex-wrap py-3 bg-footer">
      <li className="w-full md:w-1/3 pb-3">
        <a href="https://agora.engie.com/" className="highlight">
          <b>Agora</b>
        </a>
        <p>Learn, collaborate and act on data uses</p>
      </li>
      <li className="w-full md:w-1/3 pb-3">
        <a href="https://commondatahub.engie.com/" className="highlight">
          common<b>DataHub</b>
        </a>
        <p>Store, share & play with data</p>
      </li>
      <li className="w-full md:w-1/3 pb-3">
        <a href="https://common.api.engie.com/" className="highlight">
          common<b>API</b>
        </a>
        <p>Learn, use & expose APIs</p>
      </li>
    </ul>
    <div className="flex justify-between font-bold my-6 mx-8 footer-light">
      <ul className="social flex justify-between">
        <li>
          <a href="https://twitter.com/engiegroup">
            <Twitter className="h-4 fill-current" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/EngieFR">
            <Facebook className="h-4 fill-current" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/engie/">
            <Instagram className="h-4 fill-current" />
          </a>
        </li>
      </ul>
      <div className="links uppercase text-base">
        <a className="mr-6" href="/data_privacy">
          <Trans id="Privacy policy mention" component={null} >Privacy policy mention</Trans>
        </a>
        <a
          className="mr-6"
          target="_blank"
          href="/pdf/Agora_-_Guide_de_bonnes_pratiques_utilisateur_V0.4.pdf"
        >
          <Trans id="Good use of Agora" component={null} >Good use of Agora</Trans>
        </a>
        <a href="mailto:agora@engie.com">Contact</a>
      </div>
    </div>
    <div className="pb-6 flex justify-center items-center text-sm">
      <img className="mr-4" src="/images/footer-logo.jpg" alt="Engie" />
      Energy is data & data is our common wealth
    </div>
  </div>
);
