import { Suspense, useEffect } from "react";
import { connect } from "redux-bundler-react";
import navHelper from "internal-nav-helper";
import HeaderMenu from "../modules/HeaderMenu/HeaderMenu";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { Footer } from "../components/Footer/Footer";
import AgreementsPopin from "../components/AgreementsPopin/AgreementsPopin";
import MandatoryBUPopin from "../components/MandatoryBUPopin/MandatoryBUPopin";
import Konami from "react-konami-code";
import { defaultLocale, dynamicActivate } from "../i18n";
import messagesEn from "../locales/en/messages";

i18n.load({
  en: messagesEn,
});
i18n.activate("en");

interface PropTypes {
  userId?: string;
  doUpdateUrl: () => void;
  route: React.ComponentType;
  userLang: string;
  pathname: React.ComponentType;
  isLoggedIn: boolean;
  isUserAdmin: boolean;
}

const Layout = ({ userId, doUpdateUrl, route, userLang, pathname, isLoggedIn, isUserAdmin }: PropTypes) => {
  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [])
  const Page = route;

  return <I18nProvider i18n={i18n}>
    <main
      onClick={navHelper(doUpdateUrl)}
      className="flex flex-col min-h-full relative"
    >
      <HeaderMenu isAdmin={isUserAdmin} />
      <div className="flex-1 flex flex-col">
        <Suspense fallback={null}>{isLoggedIn ? <Page /> : null}</Suspense>
      </div>
      <Footer />
      {userId && <MandatoryBUPopin userId={userId} />}
      <AgreementsPopin />
    </main>
    <Konami />
  </I18nProvider>
}

export default connect(
  "doUpdateUrl",
  "selectUserId",
  "selectPathname",
  "selectIsLoggedIn",
  "selectRoute",
  "selectUserLang",
  "selectIsUserAdmin",
  Layout
);
