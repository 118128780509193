const newsletter = {
  name: "newsletter",
  doSendTestDigest: ({
    // headerText,
    headerBackgroundImage,
    introduction,
    introductionL2,
    introductionImage,
    projectIds,
    usecaseMore,
    postIds,
    postMore,
    eventIds,
    eventMore,
    conclusion,
    // footerText,
    footerBackgroundImage,
    mailSubject
  }) => ({ apiFetch }) => {
    return apiFetch("admin/digest-test", {
      method: "POST",
      body: {
        // headerText,
        headerBackgroundImage,
        introduction,
        introductionL2,
        introductionImage,
        projectIds,
        usecaseMore,
        postIds,
        postMore,
        eventIds,
        eventMore,
        conclusion,
        // footerText,
        footerBackgroundImage,
        mailSubject
      }
    });
  },

  doSendProdDigest: ({
    // headerText,
    headerBackgroundImage,
    introduction,
    introductionL2,
    introductionImage,
    projectIds,
    usecaseMore,
    postIds,
    postMore,
    eventIds,
    eventMore,
    conclusion,
    // footerText,
    footerBackgroundImage,
    mailSubject
  }) => ({ apiFetch }) => {
    return apiFetch("admin/digest-prod", {
      method: "POST",
      body: {
        // headerText,
        headerBackgroundImage,
        introduction,
        introductionL2,
        introductionImage,
        projectIds,
        usecaseMore,
        postIds,
        postMore,
        eventIds,
        eventMore,
        conclusion,
        // footerText,
        footerBackgroundImage,
        mailSubject
      }
    });
  }
}
export default newsletter;
