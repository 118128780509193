import {
  FunctionComponent,
  useState,
  ReactElement
} from "react";
import classNames from "classnames";
import { Trans } from "@lingui/macro";
import { Button, ButtonKind } from "../Button/Button";
import { CustomModal, CustomModalPropTypes } from "./CustomModal";

export enum ModalKind {
  default,
  monoButton
}

export interface SimpleModalPropTypes extends CustomModalPropTypes {
  content?: string | ReactElement;
  buttonKind?: ButtonKind;
  onClick?: () => Promise<void>;
  onClickSuccess?: () => void;
  type?: ModalKind;
  confirmContent?: string | ReactElement;
}

export const SimpleModal: FunctionComponent<SimpleModalPropTypes> = ({
  header,
  content,
  onClick = () => Promise.resolve(),
  onClickSuccess,
  buttonKind = ButtonKind.default,
  closeModal,
  isOpen,
  type = ModalKind.default,
  confirmContent
}) => {
  const [isOnClickLoading, setIsOnClickLoading] = useState(false);

  function handleClick() {
    setIsOnClickLoading(true);
    return onClick().then(() => {
      setIsOnClickLoading(false);
      onClickSuccess && onClickSuccess();
      closeModal();
    });
  }

  return (
    <CustomModal header={header} isOpen={isOpen} closeModal={closeModal}>
      {content && (
        <div className="mb-8 text-inter-base px-10 pricing-content">
          <p>{content}</p>
        </div>
      )}
      <div
        className={classNames("flex justify-end px-10", {
          "justify-center items-center": ModalKind.monoButton
        })}
      >
        {type !== ModalKind.monoButton && (
          <span className="flex mr-4">
            <Button kind={ButtonKind.neutral} onClick={closeModal}>
              <Trans id="Cancel" component={null}>Cancel</Trans>
            </Button>
          </span>
        )}
        <Button
          kind={buttonKind}
          onClick={handleClick}
          isLoading={isOnClickLoading}
        >
          {confirmContent ? confirmContent : <Trans id="Confirm" component={null}>Confirm</Trans>}
        </Button>
      </div>
    </CustomModal>
  );
};
