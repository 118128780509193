import { FC } from "react";
import { i18n } from "@lingui/core";


i18n.activate("en");

interface DatePropsType {
  date: Date;
  endDate?: Date;
}

export const CustomDate: FC<DatePropsType> = ({
  date,
  endDate
}: DatePropsType) => {
  function getDateFormat(date) {
    return `${date.getUTCDate()}/${date.getUTCMonth()}/${date.getUTCFullYear()}`;
  }

  function isFullDay(date) {
    return `${date.getUTCHours()}-${date.getUTCMinutes()}` === "0-0";
  }


  if (!endDate) {
    return <div>{i18n.date(date, { dateStyle: "medium", timeStyle: "short" })}</div>
  } else {
    const isSameDate = getDateFormat(date) === getDateFormat(endDate);
    if (!isSameDate) {
      return (
        <div>
          {i18n.date(date, { dateStyle: "medium", timeStyle: "short" })} -{" "}
          {i18n.date(endDate, { dateStyle: "medium", timeStyle: "short" })}
        </div>
      );
    } else {
      if (isFullDay(date)) {
        return (
          <div>
            {i18n.date(date, { dateStyle: "long", timeStyle: "short" })}
          </div>
        );
      } else {
        if (date.getTime() === endDate.getTime()) {
          return (
            <div>{i18n.date(date, { dateStyle: "long", timeStyle: "medium" })}</div>
          );
        } else {
          return (
            <div>
              {i18n.date(date, { dateStyle: "long", timeStyle: "medium" })}{" "}
              -{" "}
              {i18n.date(endDate, { dateStyle: "short", timeStyle: "long" })}
            </div>
          );
        }
      }
    }
  }
};

/* const Test = ({ value, format }) =>
  new Intl.DateTimeFormat(format).format(value); */
