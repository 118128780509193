import { Trans } from "@lingui/macro";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { connect } from "redux-bundler-react";

import { ReactComponent as ArrowLeft } from "../../images/svg/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../images/svg/icons/arrow-right.svg";
import { isEnded } from "../../services/utils";
import { EventType } from "../../types/events";
import { PaginationType } from "../../types/pagination";
import { Pagination } from "../Pagination/Pagination";
import EventCard from "./EventCard";

interface EventPropTypes {
  events: EventType[];
  isCompact?: boolean;
  isUserAdmin?: boolean;
  isTitleVisible?: boolean;
  searchDate?: { month: number; year: number };
  onlyActiveEvents?: boolean;
  pagination?: PaginationType;
  doUpdateQuery: (queryParams: { [key: string]: string | undefined }) => void;
  queryObject: {
    query?: string | undefined;
    bus?: string | undefined;
    page?: string | undefined;
  };
}

const EventList: FunctionComponent<EventPropTypes> = ({
  events,
  isCompact = false,
  isUserAdmin = false,
  isTitleVisible = true,
  searchDate,
  onlyActiveEvents,
  pagination,
  doUpdateQuery,
  queryObject
}) => {
  const filtredEvents = onlyActiveEvents
    ? events.filter(event => !isEnded(event.endDate))
    : events;
  const eventsToDisplay =
    isCompact && events.length > 4 ? events.slice(0, 5) : filtredEvents;

  const containerClasses = "w-full flex flex-col items-center";

  const PreviousNextLinks = () => {
    if (!searchDate) {
      return null;
    }

    const previousMonthLink = {
      month: searchDate.month - 1 === 0 ? 12 : searchDate.month - 1,
      year: searchDate.month - 1 === 0 ? searchDate.year - 1 : searchDate.year
    };

    const nextMonthLink = {
      month: searchDate.month + 1 > 12 ? 1 : searchDate.month + 1,
      year: searchDate.month + 1 > 12 ? searchDate.year + 1 : searchDate.year
    };

    return (
      <>
        {!isCompact && (
          <hr className="h-1 shadow border-gray-100 mb-3 w-full" />
        )}
        <div className="flex w-full text-sm">
          <a
            href={`?month=${previousMonthLink.month}&year=${
              previousMonthLink.year
            }`}
            className="flex w-1/2 uppercase text-blue-700 font-bold text-xs items-center"
          >
            <ArrowLeft className="h-4 inline-block fill-current" />
            &nbsp;<Trans id="Last month" component={null}>Last month</Trans>
          </a>
          <a
            href={`?month=${nextMonthLink.month}&year=${nextMonthLink.year}`}
            className="flex w-1/2 uppercase text-blue-700 font-bold text-xs items-center justify-end"
          >
            <Trans id="Next month" component={null}>Next month</Trans>&nbsp;
            <ArrowRight className="h-4 inline-block fill-current" />
          </a>
        </div>
      </>
    );
  };

  // @ts-ignore
  return (
    <div
      className={classNames(containerClasses, {
        "pt-3": isCompact,
        "py-3": !isCompact
      })}
    >
      {eventsToDisplay.length ? (
        <>
          <div
            className={classNames(
              "mb-2 flex flex-wrap text-neutral-8 justify-between w-full",
              { "flex-col font-semibold": isCompact }
            )}
          >
            {eventsToDisplay.map(event => (
              <EventCard
                key={event.id}
                {...event}
                isCompact={isCompact}
                isUserAdmin={isUserAdmin}
              />
            ))}
          </div>
        </>
      ) : (
        <p className="text-neutral-8 m-3">
          <Trans id="There is no event" component={null}>There is no event</Trans>
        </p>
      )}

      {!isCompact && <PreviousNextLinks />}

      {!isCompact && pagination && (
        <Pagination
          {...pagination}
          updateQueryParams={doUpdateQuery}
          currentQueryParams={queryObject}
        />
      )}
    </div>
  );
};

export default connect(
  "doUpdateQuery",
  "selectQueryObject",
  EventList
);
