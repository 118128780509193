import {
  FunctionComponent,
  Fragment,
  ChangeEvent,
  FocusEvent,
  ReactNode
} from "react";
import { CharacterController } from "../../components/CharacterController/CharacterController";
import classNames from "classnames";

interface PropTypes {
  id: string;
  placeholder?: string;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: FocusEvent<any>) => void;
  onKeyPress?: (ev: React.KeyboardEvent<HTMLElement>) => void;
  value?: string;
  label?: string | ReactNode;
  name?: string;
  required?: boolean;
  maxLength?: number;
  isSearchInput?: boolean;
  disabled?: boolean;
  type?: string;
  mask?: string;
  inputClassName?: string;
  labelClassName?: string;
}

export const Input: FunctionComponent<PropTypes> = ({
  placeholder = "",
  onChange,
  onFocus,
  onKeyPress,
  value,
  label,
  id,
  name,
  required,
  maxLength,
  isSearchInput,
  disabled = false,
  type = "text",
  mask,
  inputClassName = "",
  labelClassName = ""
}) => {
  const inputClasses = classNames(
    inputClassName,
    "w-full py-2 pr-3 pl-2",
    "text-neutral-5 leading-tight",
    "shadow appearance-none border border-neutral-3 rounded",
    "focus:outline-none",
    {
      "focus:rounded-b-none": isSearchInput,
      "focus:shadow-outline": !isSearchInput,
      "bg-neutral-1": disabled
    }
  );

  return (
    <Fragment>
      {label && (
        <label
          htmlFor={id}
          className={classNames(labelClassName, "default-input")}
        >
          {label}
          <CharacterController maxLength={maxLength} value={value} />
        </label>
      )}
      <input
        className={inputClasses}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        value={
          mask &&
          String(value).length >= 0 &&
          String(value).length <= String(mask).length
            ? mask
            : value
        }
        required={required}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Fragment>
  );
};
