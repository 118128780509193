const domain = "https://dev-391406.oktapreview.com";

 

const config = {

  url: domain,

  issuer: `${domain}/oauth2/default`,

  redirectUri: window.location.origin,

  clientId: "0oafvrdj9beGH33bC0h7"

};
export default config;
