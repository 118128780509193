import { FC, PropsWithChildren, ReactNode } from "react";
import { Button, ButtonKind } from "../Button/Button";
import classNames from "classnames";
import { useComponentVisible } from "../../hooks/UseIsComponentVisible";

interface DropdownPropTypes {
  buttonLabel?: string | ReactNode;
  ligthMode?: boolean;
  leftMode?: boolean;
  withTriangle?: boolean;
  closePanelOnClick?: boolean;
  buttonKind?: ButtonKind;
}

export const Dropdown: FC<PropsWithChildren<DropdownPropTypes>> = ({
  buttonLabel = "",
  ligthMode = false,
  children,
  leftMode,
  withTriangle = true,
  closePanelOnClick = false,
  buttonKind = ButtonKind.transparent
}) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const buttonClass = classNames("notranslate", {
    "text-neutral-1": ligthMode
  });

  function toggleVisibility() {
    setIsComponentVisible(!isComponentVisible);
  }

  function closePanel() {
    if (closePanelOnClick) setIsComponentVisible(false);
  }

  const pinAlign = leftMode ? "left-0" : "right-0";

  return (
    <div className="relative" ref={ref}>
      <Button kind={buttonKind} onClick={toggleVisibility}>
        <div className={buttonClass} data-cy-dropdown>
          {buttonLabel}
        </div>
        {withTriangle ? (
          <span className="text-icon ml-2 my-auto">
            {isComponentVisible ? "▲" : "▼"}
          </span>
        ) : null}
      </Button>
      {isComponentVisible ? (
        <div
          className={`absolute z-10 ${pinAlign}`}
          data-panel
          onClick={closePanel}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
