import { ContentTypes } from "../../../types/inspirations";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

export const getShareMailToLink = (
  contentType: ContentTypes,
  id: string
) => {
  let subject = "";
  let body = "";

  if (contentType === ContentTypes.PROJECT) {
    subject = i18n._(t`Discover this use case from Agora`);
    body = `${window.location.origin}/projects/${id}`;
  }

  if (contentType === ContentTypes.POST) {
    subject = i18n._(t`Discover this article from Agora`);
    body = `${window.location.origin}/resources/articles/${id}`;
  }

  if (contentType === ContentTypes.INITIATIVE) {
    subject = i18n._(t`Discover this inspiration from Agora`);
    body = `${window.location.origin}/inspirations/${id}`;
  }

  if (contentType === ContentTypes.PROFILE) {
    subject = i18n._(t`Discover this profile from Agora`);
    body = `${window.location.origin}/profile/${id}`;
  }

  if (contentType === ContentTypes.BE) {
    subject = i18n._(t`Discover this BE from Agora`);
    body = `${window.location.origin}/bes/${id}`;
  }

  if (contentType === ContentTypes.BU) {
    subject = i18n._(t`Discover this BU from Agora`);
    body = `${window.location.origin}/bus/${id}`;
  }

  return `mailto:?subject=${subject}&body=${body}`;
};
