import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { User } from "../../types/user";
import { ProfileTagsForm } from "../../modules/ProfileForm/ProfileTagsForm";
import { t, Trans } from "@lingui/macro";

import BeForm from "../../modules/BeForm/BeForm";
import { Button } from "../Button/Button";
import { TagType } from "../../types/tag";
import { i18n } from "@lingui/core";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

interface PropTypes {
  doFetchProfile: (userId: string) => Promise<User>;
  doFetchTags: () => Promise<TagType[]>;
  userId: string;
  beTags: TagType[];
  buTags: TagType[];
  doUpdateProfile: (profile: {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    description: string;
    tags: number[];
    bus: number[];
    bes: number[];
    cdoOf: number | null;
  }) => Promise<void>;
}

const MandatoryBUPopin: FunctionComponent<PropTypes> = ({
  doUpdateProfile,
  doFetchProfile,
  doFetchTags,
  userId,
  beTags,
  buTags
}) => {
  useEffect(() => {
    doFetchTags().then(() => {
      doFetchProfile(userId).then(userProfile => {
        setUserProfile(userProfile);
      });
    });
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [userProfile, setUserProfile] = useState<User>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [selectedBus, setSelectedBus] = useState<TagType[]>([]);
  const [selectedBETags, setSelectedBETags] = useState<TagType[]>([]);

  const selectedBusIds = selectedBus.map(selectedBu => selectedBu.id);
  const selectedBETagIds = selectedBETags.map(
    selectedBETag => selectedBETag.id
  );

  const remainingTeams = buTags.filter(tag => !selectedBusIds.includes(tag.id));

  const beTagsOfSelectedBUs = beTags;

  async function onSubmit() {
    if (userProfile) {
      if (selectedBus.length > 1) {
        setErrorMessage(i18n._(t`You can only select only one BU`));
        return;
      }
      await doUpdateProfile({
        id: userProfile.id,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        description: userProfile.description,
        tags: userProfile.tags.map(tag => tag.id),
        bus: selectedBusIds,
        bes: selectedBETagIds,
        cdoOf: userProfile.cdoOf ? userProfile.cdoOf : null
      }).then(() => {
        setIsSubmitted(true);
      });
    }
  }

  return userProfile && userProfile.bus.length === 0 && !isSubmitted ? (
    <div
      className="absolute h-full top-0 left-0 w-full"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="flex items-center justify-center h-screen">
        <div className="p-6 w-1/2" style={{ backgroundColor: "white" }}>
          <p className="font-bold text-center text-neutral-6">
            <Trans id="Please indicate your entity to fully enjoy the feature of Agora.
            </Trans>" component={null}>
              Please indicate your entity to fully enjoy the feature of Agora.
            </Trans>
          </p>
          <div className="mt-4 text-center">
            <ErrorMessage message={errorMessage} />
          </div>
          <div className="mt-4">
            <ProfileTagsForm
              selectedTags={selectedBus}
              setSelectedTags={setSelectedBus}
              tags={remainingTeams}
              placeholder={i18n._(t`Your entity`)}
              label={i18n._(t`Select your entity`) + "*"}
              inputId="team-search"
              onlyOne
            />
          </div>
          <div className="mt-4">
            <ProfileTagsForm
              selectedTags={selectedBETags}
              setSelectedTags={setSelectedBETags}
              tags={beTagsOfSelectedBUs}
              placeholder={i18n._(t`Your geography`)}
              label={i18n._(t`Select your geography`)}
              inputId="BETag"
              onlyOne
            />
            <BeForm selectedBUTags={selectedBus} />
          </div>
          <Button className="mt-4" type="button" onClick={onSubmit}>
            <Trans id="Submit" component={null}>Submit</Trans>
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default connect(
  "doFetchProfile",
  "doFetchTags",
  "selectBuTags",
  "selectBeTags",
  "doUpdateProfile",
  (MandatoryBUPopin)
);
