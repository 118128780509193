import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonKind } from "../Button/Button";
import { Trans } from "@lingui/macro";
import {
  ProjectInspiration,
  ProjectInspirationInput
} from "./ProjectInspirationInput";
import LinkButton from "../LinkButton/LinkButton";
import { Input } from "../Input/Input";
import { Loader } from "../Loader/Loader";

interface InspirationsFormPropTypes {
  projects: ProjectInspiration[];
  onSubmit: (associatedProjects: string[]) => void;
  onCancel: () => void;
}

export const InspirationsForm: FunctionComponent<InspirationsFormPropTypes> = ({
  projects,
  onSubmit,
  onCancel
}) => {
  const [newAssociations, setNewAssociations] = useState<string[]>([]);
  const [filterKeyword, setFilterKeyword] = useState<string>("");
  const [confirmButtonBusy, setConfirmButtonBusy] = useState<boolean>(false);

  useEffect(() => {
    projects.forEach(project => {
      if (project.isAssociated) {
        setNewAssociations(newAssociations =>
          newAssociations.concat(project.id.toString())
        );
      }
    });
  }, []);

  function onChange({ target }) {
    const projectId = target.name;

    target.checked
      ? setNewAssociations(newAssociations => newAssociations.concat(projectId))
      : setNewAssociations(newAssociations =>
        newAssociations.filter(
          currentProjectId => currentProjectId !== projectId
        )
      );
  }

  function submit(event) {
    setConfirmButtonBusy(true);
    event.preventDefault();
    onSubmit(newAssociations);
  }

  if (!projects.length)
    return (
      <div className="flex flex-col items-center">
        <p className="text-neutral-8 mb-2">
          <Trans id="You currently have no use case." component={null}>You currently have no use case.</Trans>
        </p>
        <LinkButton url="/projects/create" withIcon>
          <Trans id="Create project" component={null}>Create project</Trans>
        </LinkButton>
      </div>
    );

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(filterKeyword.toLowerCase())
  );

  return (
    <form className="max-w-lg" onSubmit={submit} name="inspiration">
      <div className="mx-4 mb-2 flex justify-center items-center">
        <div className="w-auto mr-4">
          <Trans id="Search" component={null}>Search</Trans>:
        </div>
        <Input
          id="filter"
          onChange={e => {
            setFilterKeyword(e.target.value);
          }}
        />
      </div>
      {filteredProjects.length > 0 ? (
        <ul className="mb-4 overflow-auto max-h-md">
          {filteredProjects.map(project => (
            <ProjectInspirationInput
              key={project.id}
              project={project}
              isAssociated={newAssociations.indexOf(project.id.toString()) > -1}
              onChange={onChange}
            />
          ))}
        </ul>
      ) : (
        <div className="flex flex-col items-center">
          <p className="text-neutral-8 m-4 mt-2">
            <Trans id="There are no use cases with this search" component={null}>There are no use cases with this search</Trans>
          </p>
        </div>
      )}
      <div className="flex justify-between px-10">
        <span className="flex mr-4">
          <Button kind={ButtonKind.warning} onClick={onCancel}>
            <Trans id="Cancel" component={null}>Cancel</Trans>
          </Button>
        </span>
        <Button type="submit">
          {confirmButtonBusy ? <Loader isLoading /> : <Trans id="Confirm" component={null}>Confirm</Trans>}
        </Button>
      </div>
    </form>
  );
};
