import { FunctionComponent, PropsWithChildren } from "react";
import { ReactComponent as IconAdd } from "../../images/svg/icons/icon-add.svg";
import { ButtonKind } from "../Button/Button";
import classNames from "classnames";

interface PropTypes {
  url: string;
  withIcon?: boolean;
  kind?: ButtonKind;
  target?: string;
  className?: string;
}

const LinkButton: FunctionComponent<PropsWithChildren<PropTypes>> = ({
  url,
  withIcon,
  children,
  kind = ButtonKind.default,
  target = "self",
  className
}) => {
  const computedClasses = classNames(
    "common-button items-center justify-center",
    className,
    {
      "button-default": kind === ButtonKind.default,
      "button-info": kind === ButtonKind.info,
      "button-warning": kind === ButtonKind.warning,
      "button-success": kind === ButtonKind.success,
      "button-error": kind === ButtonKind.error
    }
  );
  return (
    <a href={url} className={computedClasses} target={target}>
      {withIcon ? <IconAdd className="h-6 -ml-3 fill-current" /> : null}
      {children}
    </a>
  );
};

export default LinkButton;
