import React from "react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { ProjectCard } from "../../components/ProjectCard/ProjectCard";
import NewsCard from "../../components/NewsCard/NewsCard";
import GeneralMetrics from "../../modules/SideColumn/GeneralMetrics/GeneralMetrics";
import EventList from "../../components/EventList/EventList";
import { ProjectHeaderType } from "../../types/project";
import { Post } from "../../types/post";
import { ContentTypes } from "../../types/inspirations";
import { EventType } from "../../types/events";
import { MetricsType } from "../../types/metrics";
import ImageExplore from "../../images/png/explore.png";
import ImageSearch from "../../images/png/search.png";
import ImageDevelop from "../../images/png/develop.png";
import ImageDataPolicy from "../../images/png/DataPolicy.png";
import routeNames from "../../consts/routeNames";

export const getQuickLinks = (userId: string) => [
  {
    name: "projects",
    highlight: i18n._(t`homebox_Explore`),
    description: i18n._(t`Use Cases at ENGIE`),
    link: "/projects",
    image: ImageExplore
  },
  {
    name: "inspirations",
    highlight: i18n._(t`homebox_Develop`),
    description: i18n._(t`your business with data`),
    link: "/inspirations",
    image: ImageDevelop
  },
  {
    name: "search",
    highlight: i18n._(t`homebox_Search`),
    description: i18n._(t`for people and content`),
    link: routeNames.searchNav,
    image: ImageSearch
  },
  {
    name: "covid",
    highlight: i18n._(t`homebox_Manage`),
    description: 'THE FRAMEWORK OF OUR DATA ACTION',
    link: "https://agora-strapi.dsa-prod.myoddcloud.com/uploads/ENGIE_DATA_POLICY_V1_097504e872.pdf",
    image: ImageDataPolicy,
    target: "_blank"
  }
];

export const getNewsContent = (
  latestProject: ProjectHeaderType,
  latestPost: Post,
  doBookmarkContent: (content: {
    contentId: string;
    type: ContentTypes;
    isBookmarked: boolean;
  }) => Promise<{}>,
  metrics: MetricsType,
  events: EventType[],
) => {
  let eventList = events;
  if (eventList.length > 5) {
    eventList = eventList.slice(0, 5);
  }

  return [
    {
      name: i18n._(t`Latest UC`),
      component: (
        <ProjectCard
          {...latestProject}
          isFluid
          onBookmarkClick={doBookmarkContent}
        />
      )
    },
    {
      name: i18n._(t`Available content`),
      component: <GeneralMetrics metrics={metrics} />
    },
    {
      name: i18n._(t`Latest Article`),
      component: (
        <NewsCard
          {...latestPost}
          isSmall
          isFluid
          onBookmarkClick={doBookmarkContent}
        />
      )
    },
    {
      name: i18n._(t`Events`),
      component: (
        <EventList events={eventList} isCompact isTitleVisible={false} />
      )
    }
  ];
};
