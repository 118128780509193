import { FunctionComponent } from "react";
import classNames from "classnames";
import { Trans } from "@lingui/react";
import { isLinkActive } from "../../../services/utils";
import { navItemType } from "../HeaderMenu";

interface LinkPropTypes {
  item: navItemType;
  currentRoute?: string;
}

export const HeaderLink: FunctionComponent<LinkPropTypes> = ({
  item,
  currentRoute = ""
}) => {
  const isActive = isLinkActive(item.url, currentRoute);
  const borderColor = isActive ? "border-neutral-0" : "border-transparent";
  const linkClass = classNames(
    "px-1",
    "mx-2",
    "mt-1",
    "py-5",
    "border-solid",
    "border-b-4",
    "hidden lg:block",
    "z-50",
    borderColor
  );

  const { url, Icon, label } = item;
  return (
    <li className={linkClass}>
      <a
        className="no-underline text-neutral-1 flex items-center text-center"
        href={url}
      >
        {Icon && <Icon className="text-neutral-1 fill-current w-4 h-4 mx-2" />}
        {label && <Trans id={label} render={null}>{label}</Trans>}
      </a>
    </li>
  );
};
