export const trackEventUsefulLink = (linkType, link) => {
  return;
  /* // @ts-ignore global and not defined in local
  if (typeof gtag === "function") {
    // @ts-ignore global and not defined in local
    gtag("event", "User action", {
      event_category: "Link",
      event_label: linkType,
      value: link
    });
  } */
};

export const trackEventShare = (contentType, link) => {
  return;
  /* // @ts-ignore global and not defined in local
  if (typeof gtag === "function") {
    // @ts-ignore global and not defined in local
    gtag("event", "User action", {
      event_category: "Share",
      event_label: contentType,
      value: link
    });
  } */
};
