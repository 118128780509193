import { Fragment, FunctionComponent } from "react";
import { Trans } from "@lingui/macro";
import { ReactComponent as IconLink } from "../../../images/svg/icons/icon-link.svg";

interface LinkActionElementPropTypes {}

export const LinkActionElement: FunctionComponent<
  LinkActionElementPropTypes
> = () => (
  <Fragment>
    <IconLink className="fill-current w-5 h-5 mr-2" data-cy-link />
    <Trans id="Link to use case" component={null}>Link to use case</Trans>
  </Fragment>
);
