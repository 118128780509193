import { createRouteBundle } from "redux-bundler";
import { lazy } from "react";
import Home from "../pages/Home/Home";
import Notifications from "../pages/Notifications/Notifications";
import routeNames from "../consts/routeNames";
import Look4dataSearch from "../pages/Look4dataSearch/Look4dataSearch";

const Communities = lazy(() => import("../pages/Community/Communities"));
const Event = lazy(() => import("../pages/Event/Event"));
const EventsList = lazy(() => import("../pages/EventsList/EventsList"));
const Resources = lazy(() => import("../pages/Resources/Resources.tsx"));
const Article = lazy(() => import("../pages/Article/Article.tsx"));
const Projects = lazy(() => import("../pages/Projects/Projects.tsx"));
const CreateProject = lazy(() =>
  import("../pages/CreateProject/CreateProject.tsx")
);
const Project = lazy(() => import("../pages/Project/Project.tsx"));
const Data4UHome = lazy(() => import("../pages/Data4U/Data4UHome.tsx"));
const Data4uDomains = lazy(() => import("../pages/Data4U/Domains/Domains"));
const Data4uPlatformSingularity = lazy(() =>
  import("../pages/Data4U/Platforms/Singularity")
);
const Data4uCatalogCatalog = lazy(() =>
  import("../pages/Data4U/Catalog/Catalog")
);
const Data4uCatalogProduct = lazy(() =>
  import("../pages/Data4U/Catalog/Product")
);
const Data4uPlatform = lazy(() => import("../pages/Data4U/Platforms/Platform"));
const Initiatives = lazy(() => import("../pages/Initiatives/Initiatives.tsx"));
const Initiative = lazy(() => import("../pages/Initiative/Initiative.tsx"));
const CreateInitiative = lazy(() =>
  import("../pages/CreateInitiative/CreateInitiative.tsx")
);
const Community = lazy(() => import("../pages/Community/Community"));
const CommunityBU = lazy(() => import("../pages/Community/CommunityBU"));
const CommunityBE = lazy(() => import("../pages/Community/CommunityBE"));
const CommunityCollab = lazy(() =>
  import("../pages/Community/CommunityCollab")
);
const SearchNav = lazy(() => import("../pages/SearchNav/SearchNav.tsx"));
const Search = lazy(() => import("../pages/Search/Search.tsx"));
const Dsc = lazy(() => import("../pages/Dsc/Dsc.tsx"));
const TagResult = lazy(() =>
  import("../pages/TagResult/TagResult.container.tsx")
);
const BuResult = lazy(() => import("../pages/BuResult/BuResult.container.tsx"));
const BeResult = lazy(() => import("../pages/BeResult/BeResult.container.tsx"));
const Admin = lazy(() => import("../pages/Admin/Admin"));
const AdminStrapiIconsList = lazy(() =>
  import("../pages/Admin/StrapiIconsList/StrapiIconsList")
);
const Profile = lazy(() => import("../pages/Profile/Profile"));
const NotFound = lazy(() => import("../pages/NotFound/NotFound"));
const DataPrivacy = lazy(() => import("../pages/DataPrivacy/DataPrivacy"));
const PowerBI = lazy(() => import("../pages/PowerBI/PowerBI"));
const DataAtENGIE = lazy(() => import("../pages/DataAtENGIE/DataAtENGIE"));
const ProgramAgora = lazy(() => import("../pages/DataAtENGIE/ProgramAgora"));
const ProgramCDH = lazy(() => import("../pages/DataAtENGIE/ProgramCDH"));
const ProgramAPI = lazy(() => import("../pages/DataAtENGIE/ProgramAPI"));
const ProgramBuAdoption = lazy(() =>
  import("../pages/DataAtENGIE/ProgramBuAdoption")
);
const ProgramDataStrat = lazy(() =>
  import("../pages/DataAtENGIE/ProgramDataStrat")
);
const Version = lazy(() => import("../pages/Version/Version"));
const DataENGIE = lazy(() => import("../pages/DataAtENGIE/DataENGIE"));
const DataENGIEProject = lazy(() =>
  import("../pages/DataAtENGIE/DataENGIEProject")
);
const DataENGIEDATA = lazy(() => import("../pages/DataAtENGIE/DataENGIEData"));
const DataBUSADOPTION = lazy(() =>
  import("../pages/DataAtENGIE/DataBUSADOPTION")
);

const DataENGIEDPartnership = lazy(() =>
  import("../pages/DataAtENGIE/DataENGIEPartnership")
);
const Galaxy = lazy(() => import("../pages/Galaxy/Galaxy"));

const DataPolicyComplianceForm = lazy(() =>
  import("../pages/DataAtENGIE/data-policy-compliance-form")
);

const DataPolicyComplianceFormContext = lazy(() =>
  import("../pages/DataAtENGIE/data-policy-compliance-form-context")
);

export default createRouteBundle({
  "/": Home,
  "/resources": Resources,
  "/resources/:tab": Resources,
  "/resources/articles/:id": Article,
  "/projects": Projects,
  "/projects/create": CreateProject,
  "/projects/edit/:id": CreateProject,
  "/projects/:id": Project,
  "/projects/:id/:tab": Project,
  "/inspirations": Initiatives,
  "/inspirations/create": CreateInitiative,
  "/inspirations/edit/:id": CreateInitiative,
  "/inspirations/:id": Initiative,
  "/data4u": Data4UHome,
  "/data4u/domain/:id": Data4uDomains,
  "/data4u/product/:id": Data4uCatalogProduct,
  "/data4u/catalog/:id": Data4uCatalogCatalog,
  "/data4u/platform/singularity": Data4uPlatformSingularity,
  "/data4u/platform/:id": Data4uPlatform,
  "/dataengie": DataAtENGIE,
  "/dataatengie": DataENGIE,
  "/dataatengie/agora": ProgramAgora,
  "/dataatengie/cdh": ProgramCDH,
  "/dataatengie/api": ProgramAPI,
  "/dataatengie/datastrat": ProgramDataStrat,
  "/dataatengie/buadoption": ProgramBuAdoption,
  "/dataatengie/product/:id": DataENGIEProject,
  "/dataatengie/data/:id": DataENGIEDATA,
  "/dataatengie/data/:parent_id/partnership/:id": DataENGIEDPartnership,
  "/dataatengie/data-policy-maturity-assessment-tool": DataPolicyComplianceForm,
  "/dataatengie/data-policy-maturity-assessment-tool/:id": DataPolicyComplianceFormContext,
  "/databusadoption/data/:id": DataBUSADOPTION,
  "/events/list": EventsList,
  "/events/:id": Event,
  "/dsc": Dsc,
  "/directory": Community,
  "/directory/bu": CommunityBU,
  "/directory/be": CommunityBE,
  "/directory/collaborators": CommunityCollab,
  "/directory/datacommunities": Communities,
  [routeNames.searchNav]: SearchNav,
  [routeNames.agoraSearch]: Search,
  [routeNames.look4dataSearch]: Look4dataSearch,
  "/search/:tab": Search,
  "/tags/:id": TagResult,
  "/tags/:id/:tab": TagResult,
  "/bes/:id": BeResult,
  "/bes/:id/:tab": BeResult,
  "/bus/:id": BuResult,
  "/bus/:id/:tab": BuResult,
  "/profile/:id/notifications": Notifications,
  "/profile/:id": Profile,
  "/profile/:id/:tab": Profile,
  "/admin": Admin,
  "/admin/strapi-icons-list": AdminStrapiIconsList,
  "/admin/:tab": Admin,
  "/admin/:tab/edit/:id": Admin,
  "/data_privacy": DataPrivacy,
  "/powerbi": PowerBI,
  "/version": Version,
  "/galaxy": Galaxy,
  "*": NotFound
});
