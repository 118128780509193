import { FunctionComponent } from "react";
import { ProjectHeaderType } from "../../types/project";
import { Post } from "../../types/post";
import { Avatar } from "../../components/Avatar/Avatar";
import { getFullName, trimText } from "../../services/utils";

import { Trans } from "@lingui/macro";
import { ReactComponent as ArrowRight } from "../../images/svg/icons/arrow-right.svg";
import { ProjectStatuses } from "../../consts/project-status";

interface propsTypes {
  content: (ProjectHeaderType | Post)[];
}

interface ContentBlock {
  content: ProjectHeaderType | Post;
}

function isProjectHeaderType(
  content: ProjectHeaderType | Post
): content is ProjectHeaderType {
  return true;
}

const BigContent: FunctionComponent<ContentBlock> = ({ content }) => {
  const { id, title, user } = content;
  const { bus, be } = user;

  const imageSrc =
    // @ts-ignore
    content.type === "post" ? "/" + content.image_in_s3 : content.fileImage;
  const linkBase = content.type === "post" ? "resources/articles" : "projects";
  const useCaseBU =
    // @ts-ignore
    content.bus && content.bus.length > 0 && content.bus[0].label;

  let statusTitle = "";
  if (isProjectHeaderType(content)) {
    const { status } = content as ProjectHeaderType;
    const { title = "" } =
      ProjectStatuses.find(({ value }) => value === status) || {};
    statusTitle = title;
  }

  return (
    <a href={`/${linkBase}/${id}`} className="block flex mb-2">
      {imageSrc && (
        <div
          className="mr-4 w-1/3 bg-cover bg-center flex-shrink-0"
          style={{
            backgroundImage: `url('${imageSrc}')`,
            height: 140
          }}
        />
      )}
      <div className="w-full">
        <div className="flex text-sm items-center pb-2 mb-2">
          <div className="mr-2">
            <Avatar user={user} xxsmall />
          </div>
          <div>
            <div>{getFullName(user)}</div>
            <div className="text-xs">
              {bus && bus[0] && bus[0].label}
              {bus && bus[0] && (be && be[0]) && " - "}
              {be && be[0] && be[0].label}
            </div>
          </div>
        </div>
        <p className="text-blue-700 font-semibold border-t border-gray-f5f5f5">
          {trimText(title, 50)}
        </p>
        <p className="text-xs text-neutral-6">
          {useCaseBU && <span>{useCaseBU} - </span>}
          {statusTitle}
        </p>
        <div className="uppercase cursor-pointer mt-4 flex items-center justify-end text-xs text-blue-700 font-bold">
          <Trans id="Read more" component={null}>Read more</Trans>{" "}
          <ArrowRight className="ml-2 h-4 inline-block fill-current" />
        </div>
      </div>
    </a>
  );
};

const SmallContent: FunctionComponent<ContentBlock> = ({ content }) => {
  const { title, user, id } = content;
  const { bus, be } = user;
  const linkBase = content.type === "post" ? "resources/articles" : "projects";
  const useCaseBU =
    // @ts-ignore
    content.bus && content.bus.length > 0 && content.bus[0].label;

  let statusTitle = "";
  if (isProjectHeaderType(content)) {
    const { status } = content as ProjectHeaderType;
    const { title = "" } =
      ProjectStatuses.find(({ value }) => value === status) || {};
    statusTitle = title;
  }

  return (
    <a
      className="block border-t-2 border-gray-f5f5f5 pt-2 mb-2 text-sm"
      href={`/${linkBase}/${id}`}
    >
      <p className="text-blue-700 font-semibold truncate">{title}</p>
      {content.type === "project" && (
        <p className="text-xs text-neutral-6">
          {useCaseBU && <span>{useCaseBU} - </span>}
          {statusTitle}
        </p>
      )}
      <div className="flex items-center mt-2">
        <span className="mr-2">
          <Avatar user={user} xsmall />
        </span>
        <span>{getFullName(user)}</span>
        <p className="text-xs">
          {bus && bus[0] && ` - ${bus[0].label}`}
          {bus && bus[0] && (be && be[0]) && " - "}
          {be && be[0] && be[0].label}
        </p>
      </div>
    </a>
  );
};

const HomeContentList: FunctionComponent<propsTypes> = ({ content }) => (
  <div className="text-neutral-8 mt-4">
    {content && content.length > 0 && <BigContent content={content[0]} />}
    {content &&
      content
        .slice(1)
        .map((element, index) => (
          <SmallContent key={index} content={element} />
        ))}
  </div>
);

export default HomeContentList;
