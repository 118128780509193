/**
 * this object define the routes for the file /bundles/routes.js
 * the key here will works like route names to easily share among the other files if needed
 * so that we can also modify the routes here once if necessary
 */
const routeNames = {
  searchNav: "/search_nav",
  agoraSearch: "/search",
  look4dataSearch: "/look4data_search"
}
export default routeNames;
