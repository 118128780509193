import { FunctionComponent } from "react";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { navItemType } from "../HeaderMenu";
interface DropdownMenuPropTypes {
  navItems: navItemType[];
}

export const DropdownMenu: FunctionComponent<DropdownMenuPropTypes> = ({
  navItems
}) => (
  <div className="py-5">
    <Dropdown buttonLabel="Menu" ligthMode leftMode>
      <ul className="mt-5">
        {navItems.map(item => (
          <li
            key={item.url}
            className="bg-neutral-1 border border-neutral-3 shadow hover:border-primary-8 px-8 py-3 text-neutral-8 hover:text-neutral-1 hover:bg-primary-8"
          >
            <a href={item.url}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </Dropdown>
  </div>
);
