import { FunctionComponent } from "react";
import { Trans } from "@lingui/macro";
import { ReactComponent as IconShare } from "../../../images/svg/icons-agora/share.svg";
import { ContentTypes } from "../../../types/inspirations";
import { getShareMailToLink } from "./utils";
import { trackEventShare } from "../../../services/analytics";
import classNames from "classnames";

interface ShareActionElementPropTypes {
  contentType: ContentTypes;
  id: string;
  className?: string;
  isButton?: boolean;
}

export const ShareActionElement: FunctionComponent<
  ShareActionElementPropTypes
> = ({ id, contentType, className, isButton }) => {
  const shareMailToLink = getShareMailToLink(contentType, id);

  function onShareLinkClick() {
    window.location.assign(shareMailToLink);
    trackEventShare(contentType, shareMailToLink);
  }

  return (
    <div
      className={classNames(
        "flex cursor-pointer",
        { "common-button items-center text-center button-default": isButton },
        className
      )}
      onClick={onShareLinkClick}
    >
      <IconShare className="fill-current w-5 h-5 mr-2" data-cy-link />
      <Trans id="Share" component={null}>Share</Trans>
    </div>
  );
};

export default ShareActionElement;
