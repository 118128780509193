export const initialState = {
  initiatives: [],
  pagination: undefined
};

const initiatives = {
  name: "initiatives",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchInitiatives: (language, tags = "") => ({ apiFetch }) =>
    apiFetch(`initiatives/header?language=${language}&tags=${tags}&limit=500`),
  doFetchInitiativeHeader: language => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(
      `initiatives/${routeParams.id}/header?language=${language}`
    );
  },
  doDeleteInitiative: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`initiatives/${routeParams.id}`, {
      method: "DELETE"
    }).then(() => store.doUpdateUrl("/inspirations"));
  },
  doSaveInitiative: ({ englishTitle, frenchTitle, image, tags }) => ({
    store,
    apiFetch
  }) => {
    const routeParams = store.selectRouteParams();
    const url = routeParams.id
      ? `initiatives/${routeParams.id}/header`
      : "initiatives/header";
    const method = routeParams.id ? "PUT" : "POST";
    return apiFetch(url, {
      method: method,
      body: {
        englishTitle,
        frenchTitle,
        image,
        tags
      }
    }).then(id => store.doUpdateUrl(`/inspirations/${id}`));
  },
  doUpdateInitiativeGeneral: ({
    expectedOutcome,
    description,
    dataType,
    dataTreatment,
    language
  }) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(
      `initiatives/${routeParams.id}/general?language=${language}`,
      {
        method: "PUT",
        body: {
          expectedOutcome,
          description,
          dataType,
          dataTreatment
        }
      }
    );
  },
  doFetchInitiativeGeneral: language => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(
      `initiatives/${routeParams.id}/general?language=${language}`
    );
  },
  doFetchInitiativeProjects: () => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`initiatives/${routeParams.id}/projects/header`);
  }
};

export default initiatives
