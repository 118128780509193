import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { ReactComponent as IconClose } from "../../images/svg/icons-agora/close.svg";
import { ReactComponent as IconClock } from "../../images/svg/icons/clock.svg";
import { ReactComponent as IconCompass } from "../../images/svg/icons/compass.svg";
import { ReactComponent as IconCheckCircle } from "../../images/svg/icons/check-circle.svg";
import { ReactComponent as IconPlayCircle } from "../../images/svg/icons/play-circle.svg";
import { ReactComponent as IconComments } from "../../images/svg/icons/comments.svg";
import { ReactComponent as IconDesktop } from "../../images/svg/icons/desktop.svg";
import { ReactComponent as IconRegister } from "../../images/svg/register.svg";
import { ReactComponent as IconCalendar } from "../../images/svg/calendar.svg";
import { Button, ButtonKind } from "../Button/Button";
import { CustomDate } from "../NewsCard/CustomDate";
import EventDeleteModal from "./modals/EventDeleteModal";
import { isEnded, trimText } from "../../services/utils";
import classnames from "classnames";
import { Trans } from "@lingui/macro";
import LinkButton from "../LinkButton/LinkButton";

interface PropTypes {
  id: number;
  title: string;
  eventLocation: string;
  description: string;
  startDate: string;
  endDate: string;
  eventType: string;
  images?: { id_image: string }[];
  isUserAdmin: boolean;
  isCompact: boolean;
  joinType?: string;
  joinUrl?: string;
  archiveUrl?: string;
  doUpdateUrl: (url: string) => void;
}

const EventCard: FunctionComponent<PropTypes> = ({
  id,
  title,
  eventLocation,
  eventType,
  images,
  description,
  startDate,
  archiveUrl,
  endDate,
  isUserAdmin,
  joinType,
  joinUrl,
  isCompact = false,
  doUpdateUrl
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [thumb, setThumb] = useState<string>();

  useEffect(() => {
    if (images && images.length) {
      setThumb(images[0].id_image);
    }
  }, []);

  function openDeleteModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
  }

  function closeModal() {
    setShowDeleteModal(false);
  }

  function onClickEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    doUpdateUrl(`/admin/events/edit/${id}`);
  }

  const AdminSection = () => (
    <div className="flex">
      <Button kind={ButtonKind.transparent} onClick={onClickEdit}>
        <span role="img" aria-label="Edit button">
          ✏️
        </span>
      </Button>
      <Button kind={ButtonKind.transparent} onClick={openDeleteModal}>
        <IconClose
          className="h-4 w-auto text-error-8 fill-current mx-2"
          data-test-close
        />
      </Button>
    </div>
  );

  const joinIconButton = !joinType ? (
    <>
      <IconCheckCircle className="w-4 text-white h-auto fill-current mr-2" />
      <Trans id="Join" component={null}>Join</Trans>
    </>
  ) : joinType === "register" ? (
    <>
      <IconRegister className="w-4 text-white h-auto fill-current mr-2" />
      <Trans id="Register" component={null}>Register</Trans>
    </>
  ) : (
    <>
      <IconCalendar className="w-4 text-white h-auto fill-current mr-2" />
      <Trans id="Add to calendar" component={null}>Add to calendar</Trans>
    </>
  );

  const joinButton = joinUrl && (
    <LinkButton
      className="flex items-center text-xs uppercase font-bold"
      url={joinUrl}
    >
      {joinIconButton}
    </LinkButton>
  );

  return (
    <Fragment>
      {showDeleteModal && (
        <EventDeleteModal id={id} closeModal={closeModal} isOpen={true} />
      )}
      <a
        className={classnames(
          "flex flex-col px-4 cursor-pointer text-neutral-8",
          {
            "mb-8 py-2": !isCompact,
            "text-sm pt-2": isCompact
          }
        )}
        style={{
          backgroundColor: isCompact ? "transparent" : "#f5f5f5",
          width: isCompact ? "100%" : "45%"
        }}
        href={`/events/${id}`}
        id="eventCard"
        data-card-event
      >
        <div
          className={classnames("flex justify-between", {
            "border-gray-c6c6c6 border-b pb-2 text-sm mb-2": !isCompact,
            "text-xs": isCompact
          })}
        >
          <span
            className={classnames(
              "text-neutral-8 flex items-center mb-2 text-xs",
              {
                "font-bold": !isCompact
              }
            )}
          >
            <IconClock
              className="flex-shrink-0 h-5 w-5 text-blue-700 fill-current mr-2"
              data-test-close
            />
            <CustomDate
              date={new Date(startDate)}
              endDate={new Date(endDate)}
            />
          </span>

          {isUserAdmin && <AdminSection />}

          {eventLocation && !isCompact && (
            <a
              className="text-neutral-8 font-bold flex items-center uppercase"
              href={`https://www.google.fr/maps/place/${eventLocation}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {eventLocation}
              <IconCompass
                className="h-5 w-auto text-blue-700 fill-current ml-2"
                data-test-close
              />
            </a>
          )}
        </div>

        <div
          className={classnames("mb-3 text-blue-700", {
            "font-bold text-xl mb-3": !isCompact,
            "border-b border-gray-c6c6c6 pb-3": isCompact
          })}
        >
          {title}
        </div>

        <div
          className={classnames({
            "flex mb-3 flex-1": !isCompact
          })}
        >
          <div
            className={classnames("w-full flex flex-col", {
              "w-1/2": !isCompact && thumb
            })}
          >
            {description && !isCompact && (
              <div
                className={classnames("break-words flex-1", {
                  "mt-6 mb-8": !isCompact
                })}
              >
                {trimText(description, thumb ? 90 : 180)}
              </div>
            )}
            {!isCompact && (
              <div className={"h-8 flex justify-center"}>
                {isEnded(endDate) ? (
                  archiveUrl && (
                    <LinkButton
                      url={archiveUrl}
                      kind={ButtonKind.transparent}
                      className="font-bold flex items-center px-3 uppercase border-2 text-xs rounded border-blue-700"
                    >
                      <IconPlayCircle className="w-4 h-auto text-blue-700 fill-current mr-2" />
                      <span className="text-blue-700">
                        <Trans id="Access archive" component={null}>Access archive</Trans>
                      </span>
                    </LinkButton>
                  )
                ) : (
                  <>{joinButton}</>
                )}
              </div>
            )}
          </div>

          {thumb && !isCompact && (
            <div className="ml-4 w-1/2">
              <img alt="thumbnail" className="w-full mb-2" src={"/" + thumb} />
            </div>
          )}
        </div>

        {eventType && !isCompact && (
          <>
            <div className="text-sm flex font-bold items-center uppercase mt-2 pt-2 border-gray-c6c6c6 border-t">
              {eventType === "online" && (
                <>
                  <IconDesktop className="text-blue-700 h-5 w-auto fill-current mr-2" />
                  <Trans id="Online" component={null}>Online</Trans>
                </>
              )}
              {eventType === "inperson" && (
                <>
                  <IconComments className="text-blue-700 h-5 w-auto fill-current mr-2" />
                  <Trans id="In person" component={null}>In person</Trans>
                </>
              )}
            </div>
          </>
        )}
      </a>
    </Fragment>
  );
};
export default connect(
  "doUpdateUrl",
  EventCard
);
