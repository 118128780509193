import { FunctionComponent, useState, Fragment, useEffect } from "react";
import { ReactComponent as IconStar } from "../../images/svg/icons-agora/bookmark.svg";
import { ReactComponent as Triangle } from "../../images/svg/icons-agora/triangle.svg";

interface BookmarkStarPropTypes {
  onClick?: (boolean) => Promise<{}>;
  selected?: boolean;
}

export const BookmarkStar: FunctionComponent<BookmarkStarPropTypes> = ({
  onClick,
  selected = false
}) => {
  const [isStarDisabled, setIsStarDisabled] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(selected);
  const color = isBookmarked ? "text-warning-4" : "text-neutral-3";

  useEffect(() => setIsBookmarked(selected), [selected]);

  function handleClick() {
    setIsStarDisabled(true);
    return (
      onClick &&
      onClick(isBookmarked).then(() => {
        setIsBookmarked(!isBookmarked);
        setIsStarDisabled(false);
      })
    );
  }

  return (
    <Fragment>
      <Triangle className="text-neutral-6 fill-current h-12 w-auto absolute" />
      <div className="absolute p-1">
        <button
          className="focus:outline-none relative absolute flex"
          type="button"
          onClick={handleClick}
          disabled={isStarDisabled}
        >
          <IconStar
            className={`${color} fill-current h-5 w-auto `}
            style={
              isStarDisabled ? { animation: "spin 0.5s linear infinite" } : {}
            }
            data-test-star
          />
        </button>
      </div>
    </Fragment>
  );
};
