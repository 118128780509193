import { useState, FunctionComponent } from "react";
import classNames from "classnames";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";

import { ReactComponent as Information } from "../../images/svg/icons-agora/question-circle.svg";
import { ReactComponent as TimesCircle } from "../../images/svg/times-circle.svg";
import {
  ProjectStatuses,
  ProjectStatusEnum
} from "../../consts/project-status";

interface StepPropTypes {
  index: number;
  hoveredElement: number;
  value: number;
  stepsLength: number;
  onClick?: (index: number) => void;
  setHoveredElement: (index: number) => void;
  readonly?: boolean;
}

const Step: FunctionComponent<StepPropTypes> = ({
  index,
  value,
  hoveredElement,
  onClick,
  setHoveredElement,
  stepsLength,
  readonly
}) => {
  const isHovered = index + 1 <= hoveredElement;
  const isSelected = index + 1 <= value;
  const commonStepClasses = classNames({
    "border-neutral-3": !isSelected,
    "bg-neutral-3": !isSelected && isHovered,
    "border-primary-8 bg-primary-8": isSelected,
    "rounded-l-lg": index === 0,
    "rounded-r-lg": index === stepsLength - 1
  });
  const onHover = !readonly ? () => setHoveredElement(index + 1) : undefined;
  return readonly ? (
    <div className="px-px py-2">
      <div className={`w-4 h-2 border ${commonStepClasses}`} />
    </div>
  ) : (
    <button
      type="button"
      className="px-1 py-4 focus:outline-none"
      onClick={() => onClick && onClick(index + 1)}
      onMouseEnter={onHover}
      data-status-step
    >
      <div className={`w-8 h-4 border-2 shadow ${commonStepClasses}`} />
    </button>
  );
};

interface PropTypes {
  value: number;
  onClick?: (index: number) => void;
  readonly?: boolean;
  withTooltip?: boolean;
}

export const ProjectStatus: FunctionComponent<PropTypes> = ({
  value,
  onClick,
  readonly,
  withTooltip
}) => {
  const [hoveredElement, setHoveredElement] = useState(0);
  const displayedStep = hoveredElement || value;
  const textClasses = classNames("ml-2", {
    "text-sm text-neutral-6": readonly
  });
  const onLeave = () => setHoveredElement(0);
  const projectStatus =
    ProjectStatuses.find(({ value }) => value === displayedStep) || null;

  if (!projectStatus) {
    return <></>;
  }

  const {
    value: projectValue,
    title,
    description: rawDescription
  } = projectStatus;
  const description = i18n._(rawDescription);

  if (projectStatus.value === ProjectStatusEnum.CLOSED) {
    return (
      <div>
        <p className="text-base text-neutral-6">
          <TimesCircle
            className={
              "mr-1 text-blue-700 fill-current h-6 w-auto inline-block"
            }
          />
          <span className="font-bold">
            <Trans id={title} component={null} />
          </span>
          {withTooltip && (
            <span data-wenk={description}>
              <Information
                className={
                  "cursor-pointer fill-current h-4 w-auto mx-2 mb-1 inline-block"
                }
              />
            </span>
          )}
        </p>
      </div>
    );
  }
  const projectStatuses = ProjectStatuses.filter(
    ({ value }) => value !== ProjectStatusEnum.CLOSED
  ).sort((a, b) => a.value - b.value);
  const { length } = projectStatuses;

  return (
    <div className="flex items-center" onMouseLeave={onLeave}>
      {projectStatuses.map(({ value }, index) => (
        <Step
          key={`status-${value}`}
          index={index}
          value={projectValue}
          hoveredElement={hoveredElement}
          onClick={onClick}
          setHoveredElement={setHoveredElement}
          stepsLength={length}
          readonly={readonly}
        />
      ))}
      {displayedStep ? (
        <p className={textClasses}>
          <Trans id="currently in" component={null}>currently in</Trans>
          &nbsp;
          <span className="font-bold">
            <Trans id={title} component={null}>{title}</Trans>
          </span>
          {withTooltip && (
            <span data-wenk={description}>
              <Information
                className={
                  "cursor-pointer fill-current h-4 w-auto mx-2 mb-1 inline-block"
                }
              />
            </span>
          )}
        </p>
      ) : null}
    </div>
  );
};

export default ProjectStatus;
