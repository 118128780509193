import { FunctionComponent } from "react";
import { TagType, TagLink, TagCategories } from "../../types/tag";
import classNames from "classnames";

interface CardTagPropTypes {
  tag: TagType;
  isSmall?: boolean;
  isPrimary?: boolean;
}

export const CardTag: FunctionComponent<CardTagPropTypes> = ({
  tag,
  isSmall,
  isPrimary = false
}) => {
  const computedClasses = classNames(
    "inline-block hover:bg-neutral-5 hover:text-neutral-1 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2",
    {
      "text-neutral-5 bg-neutral-2": !isPrimary,
      "text-sm": !isSmall,
      "text-xs": isSmall,
      "bg-primary-8 text-neutral-0": isPrimary
    }
  );

  let link;
  switch (tag.category) {
    case TagCategories.BU:
      link = TagLink.BU;
      break;
    case TagCategories.BE:
      link = TagLink.BE;
      break;
    default:
      link = TagLink.TAG;
      break;
  }

  const value: string = tag.category.includes("Z") ? `` : `/${link}/${tag.id}`

  return (
    <span key={tag.id} className={computedClasses}>
      <a
        href={value}
      >
        {tag.label}
      </a>
    </span>
  );
};
