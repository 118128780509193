import { FunctionComponent, Fragment } from "react";
import { Avatar } from "../Avatar/Avatar";
import { User } from "../../types/user";
import { getFullName } from "../../services/utils";
import { ReactComponent as Mail } from "../../../src/images/svg/icons/icon-mail.svg";

interface AuthorPropTypes {
  user: User;
}

export const Contact: FunctionComponent<AuthorPropTypes> = ({ user }) => (
  <Fragment>
    <Avatar user={user} small />
    <p className="ml-2 flex-basis">
      <a href={`/profile/${user.id}`} className="hover:underline font-bold" rel="noopener noreferrer">
        {getFullName(user)}
      </a>
      <a
        href={`mailto:${user.email}`}
        target="_blank"
        className="font-bold ml-2"
        rel="noopener noreferrer"
      >
        <Mail className="w-5 h-auto fill-current inline" />
      </a>
    </p>
  </Fragment>
);
