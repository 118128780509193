import { FunctionComponent } from "react";
import { ReactComponent as Logo } from "../../../images/svg/icons-agora/logo.svg";

export const AgoraLogo: FunctionComponent = () => (
  <a
    className="notranslate flex items-center justify-center no-underline text-neutral-1"
    href="/"
  >
    <Logo className="h-8 fill-current" />
    Agora
  </a>
);
