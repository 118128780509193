export const initialState = {};
const search = {
  name: "search",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchSearchPosts: ({ query, page = 1 }) => ({ apiFetch }) =>
    apiFetch(`posts?query=${encodeURI(query)}&page=${page}&limit=9`),
  doFetchSearchProjects: ({ query, page = 1 }) => ({ apiFetch }) =>
    apiFetch(`projects/header?query=${encodeURI(query)}&page=${page}&limit=9`),
  doFetchSearchUsers: ({ query, page = 1 }) => ({ apiFetch }) =>
    apiFetch(`users?query=${encodeURI(query)}&page=${page}&limit=12`),
  doFetchSearchInitiatives: ({ query, languageSelected, page = 1 }) => ({
    apiFetch,
    store,
    dispatch
  }) => {
    const language = languageSelected
      ? languageSelected
      : store.selectUserLang();
    dispatch({
      type: "DISABLE_INITIATIVES_PAGINATION"
    });
    return apiFetch(
      `initiatives/header?query=${encodeURI(
        query
      )}&language=${language}&page=${page}`
    );
  }
}
export default search;
