export const initialState = {};
const assets = {
  name: "assets",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },

  doUploadImage: imageData => ({ apiFetch }) =>
    apiFetch("uploadimages", {
      method: "POST",
      body: {
        fileImage: imageData
      }
    })
}
export default assets;
