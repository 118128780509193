import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from "react";
import { connect } from "redux-bundler-react";
import { Trans } from "@lingui/macro";
import { InspirationsForm } from "../../../components/InspirationsForm/InspirationsForm";
import { CustomModal } from "../../../components/Modal/CustomModal";
import { ProjectInspiration } from "../../../components/InspirationsForm/ProjectInspirationInput";
import { ContentTypes } from "../../../types/inspirations";
import { Loader } from "../../../components/Loader/Loader";
import { RouteInfoType } from "../../../types/routing";

interface InspirationModalWithoutConnectPropTypes {
  id: string;
  cardType: ContentTypes;
  closeModal: () => void;
  isOpen: boolean;
}

interface InspirationModalPropTypes
  extends InspirationModalWithoutConnectPropTypes {
  doFetchProjectsToLink: (
    type: ContentTypes,
    id: string
  ) => Promise<ProjectInspiration[]>;
  doPutAssociatedProjects: (
    type: ContentTypes,
    id: string,
    projectIds: Number[]
  ) => Promise<void>;
  routeInfo: RouteInfoType;
  isFormLoading?: boolean;
}

export const InspirationModal: FunctionComponent<InspirationModalPropTypes> = ({
  id,
  cardType,
  closeModal,
  isOpen,
  doFetchProjectsToLink,
  doPutAssociatedProjects,
  routeInfo,
  isFormLoading
}) => {
  const [projects, setProjects] = useState<ProjectInspiration[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(
    isFormLoading !== undefined ? isFormLoading : true
  );

  useEffect(() => {
    if (isOpen) {
      doFetchProjectsToLink(cardType, id).then(projects => {
        setProjects(projects);
        setIsLoading(false);
      });
    }
  }, [isOpen, routeInfo.url]);

  const submitInspirations = useCallback(
    associatedProjects =>
      doPutAssociatedProjects(cardType, id, associatedProjects).finally(() => {
        closeModal();
        setIsLoading(true);
      }),
    [doPutAssociatedProjects, cardType, id, closeModal]
  );

  return (
    <CustomModal
      header={<Trans id="Link to use case" component={null}>Link to use case</Trans>}
      closeModal={closeModal}
      isOpen={isOpen}
    >
      {isLoading ? (
        <div className="flex justify-center">
          <Loader dark isLoading />
        </div>
      ) : (
        <InspirationsForm
          projects={projects}
          onSubmit={submitInspirations}
          onCancel={closeModal}
        />
      )}
    </CustomModal>
  );
};

const connectedModal: FunctionComponent<
  InspirationModalWithoutConnectPropTypes
> = connect(
  "doFetchProjectsToLink",
  "doPutAssociatedProjects",
  "selectRouteInfo",
  InspirationModal
);

export default connectedModal;
