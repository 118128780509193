import { FunctionComponent, ReactNode } from "react";
import { Dropdown } from "../Dropdown";
import { Button, ButtonKind } from "../../Button/Button";

type ListItem = {
  label: string;
  onClick: () => void;
};

interface PropTypes {
  buttonLabel?: string | ReactNode;
  items?: Array<ListItem>;
  withTriangle?: boolean;
}

const renderItem = item => (
  <li key={item.label} className="">
    <Button kind={ButtonKind.full} onClick={item.onClick}>
      <span className="w-full notranslate bg-neutral-1 border border-neutral-3 shadow hover:border-primary-8 p-3 text-neutral-8 hover:text-neutral-1 hover:bg-primary-8">
        {item.label}
      </span>
    </Button>
  </li>
);

export const DropdownList: FunctionComponent<PropTypes> = ({
  buttonLabel,
  items = [],
  withTriangle
}) => (
  <div className="py-5">
    <Dropdown buttonLabel={buttonLabel} ligthMode withTriangle={withTriangle}>
      <ul className="m-5">{items.map(renderItem)}</ul>
    </Dropdown>
  </div>
);
