import { Fragment, FunctionComponent } from "react";
import { Trans } from "@lingui/macro";
import { ReactComponent as IconUnlock } from "../../../images/svg/icons/icon-lock-open.svg";

interface UnlinkActionElementPropTypes {}

export const UnlinkActionElement: FunctionComponent<
  UnlinkActionElementPropTypes
> = () => (
  <Fragment>
    <IconUnlock className="fill-current w-5 h-5 mr-2" data-cy-delete />
    <Trans id="Unlink inspiration" component={null}>Unlink inspiration</Trans>
  </Fragment>
);
