import { TagCategories } from "../types/tag";

export const initialState = {
  tags: []
};
const tags = {
  name: "tags",
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case "GET_TAGS_SUCCESS":
          return {
            ...state,
            tags: payload.tags
          };
        case "POST_TAGS_SUCCESS":
          return {
            ...state,
            tags: state.tags.concat(payload.newTag)
          };
        case "DELETE_TAGS_SUCCESS":
          return {
            ...state,
            tags: state.tags.filter(tag => tag.id !== payload.deletedId)
          };
        default:
          return state;
      }
    };
  },

  doFetchTags: (doDispatch = true, category) => ({ dispatch, apiFetch }) => {
    return apiFetch("tags").then(tags => {
      let filteredTags;

      if (category === TagCategories.BU) {
        filteredTags = tags.filter(tag => tag.category === TagCategories.BU);
      } else if (category === TagCategories.BE) {
        filteredTags = tags.filter(tag => tag.category === TagCategories.BE);
      } else {
        filteredTags = tags;
      }

      if (doDispatch) {
        dispatch({
          type: "GET_TAGS_SUCCESS",
          payload: { tags: filteredTags }
        });
      }
      return filteredTags;
    });
  },

  doFetchUsedBeTags: () => ({ apiFetch }) => {
    return apiFetch("bes/used").then(tags => {
      return tags;
    });
  },

  doFetchTagsByLetter: (letter = "", category) => ({ apiFetch }) => {
    return apiFetch(`tags?letter=${letter}`).then(tags => {
      if (category === TagCategories.BU) {
        return tags.filter(tag => tag.category === TagCategories.BU);
      } else if (category === TagCategories.BE) {
        return tags.filter(tag => tag.category === TagCategories.BE);
      } else {
        return tags;
      }
    });
  },

  doPostTag: ({ label, category, buId }) => ({ apiFetch, dispatch }) => {
    const bodyObject = { label, category };
    if (buId) {
      bodyObject.bu_id = buId;
    }

    return apiFetch("tags", {
      method: "POST",
      body: bodyObject
    }).then(tag =>
      dispatch({
        type: "POST_TAGS_SUCCESS",
        payload: { newTag: tag }
      })
    );
  },

  doFetchtagKPI: tagType => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`${tagType}/${routeParams.id}/kpi`);
  },

  doFetchTagProjects: (tagType, page = 1) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(
      `projects/header?${tagType}=${routeParams.id
      }&page=${page}&limit=9&sort=filling`
    );
  },
  doFetchTagUsers: (tagType, page = 1) => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`users?${tagType}=${routeParams.id}&page=${page}&limit=12`);
  },
  doFetchTagCdo: tagType => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`${tagType}/${routeParams.id}/cdo`);
  },
  doFetchTagPosts: (page = 1) => ({ store, apiFetch }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`posts?tags=${routeParams.id}&page=${page}&limit=9`);
  },
  doFetchTagInitiatives: (page = 1) => ({ store, apiFetch }) => {
    const routeParams = store.selectRouteParams();
    const language = store.selectUserLang();
    return apiFetch(
      `initiatives/header?language=${language}&tags=${routeParams.id
      }&page=${page}&limit=9`
    );
  },
  doDeleteTag: id => ({ dispatch, apiFetch }) => {
    return apiFetch(`tags/${id}`, {
      method: "DELETE"
    }).then(tag =>
      dispatch({
        type: "DELETE_TAGS_SUCCESS",
        payload: { deletedId: id }
      })
    );
  },
  doUpdateTag: tag => ({ apiFetch, store }) => {
    const routeParams = store.selectRouteParams();
    return apiFetch(`tags/${routeParams.id}`, {
      method: "PUT",
      body: tag
    }).then(() => store.doUpdateUrl(`/admin`));
  },

  doFetchLeaderboard: () => ({ apiFetch }) => {
    return apiFetch(`bus`);
  },

  selectTags: state =>
    state.tags.tags.filter(
      tag =>
        tag.category !== TagCategories.ZBU && tag.category !== TagCategories.ZBE
    ),
  selectBuTags: state =>
    state.tags.tags.filter(tag => tag.category === TagCategories.BU),
  selectBeTags: state =>
    state.tags.tags.filter(tag => tag.category === TagCategories.BE),
  selectTagsWithoutBuAndBe: state =>
    state.tags.tags.filter(
      tag =>
        tag.category !== TagCategories.BU &&
        tag.category !== TagCategories.BE &&
        tag.category !== TagCategories.ZBE &&
        tag.category !== TagCategories.ZBU
    ),
  selectTagsWithoutBuAndBeAndDatatype: state =>
    state.tags.tags.filter(
      tag =>
        tag.category !== TagCategories.BU &&
        tag.category !== TagCategories.BE &&
        tag.category !== TagCategories.ZBU &&
        tag.category !== TagCategories.ZBE &&
        tag.category !== TagCategories.DATATYPE
    ),
  selectDataTypesTags: state => {
    return state.tags.tags.filter(
      tag => tag.category === TagCategories.DATATYPE
    );
  },
  selectAllTags: state => state.tags.tags
}
export default tags;
