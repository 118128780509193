import { FunctionComponent, useState } from "react";
import { SimpleModal, ModalKind } from "../Modal/SimpleModal";
import { t } from "@lingui/macro";

import { ReactComponent as Information } from "../../images/svg/icons-agora/question-circle.svg";
import { i18n } from "@lingui/core";



export const Disclaimer: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Information
        className={
          "cursor-pointer fill-current h-4 w-auto mx-2 mb-1 inline-block"
        }
        onClick={() => {
          setIsOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal
          type={ModalKind.monoButton}
          header={i18n._(t`Disclaimer`)}
          content={
            <div className="font-normal">
              <p className="mb-5">{i18n._(t`disclamerText1`)}</p>
              <p className="mb-5">{i18n._(t`disclamerText2`)}</p>
              <p>{i18n._(t`disclamerText3`)}</p>
            </div>
          }
          closeModal={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default Disclaimer;
