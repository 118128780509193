import { Fragment, FunctionComponent } from "react";
import { Trans } from "@lingui/macro";
import { ReactComponent as IconTrash } from "../../../images/svg/icons/icon-trash.svg";

interface DeleteActionElementPropTypes {}

export const DeleteActionElement: FunctionComponent<
  DeleteActionElementPropTypes
> = () => (
  <Fragment>
    <IconTrash className="fill-current w-5 h-5 mr-2" data-cy-delete />
    <Trans id="Delete" component={null}>Delete</Trans>
  </Fragment>
);
