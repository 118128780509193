import { FeedItemPost } from "./../../types/post";
import bgImage from "../../images/png/card_bg_placeholder.png";
import { Post } from "../../types/post";
import { tags } from "../TagsSearch/Tags.fixture";
import {
  userFlorent,
  userRoman,
  userToto
} from "../MembersSearch/Members.fixture";

export const EmptyNewsCardFixture: Post = {
  id: "",
  user: {
    id: "",
    description: "",
    firstName: "",
    lastName: "",
    email: "",
    lang: "",
    isAdmin: false,
    bus: [],
    tags: [],
    be: [],
    isContributor: true,
    isAmbassador: true,
    isCdo: false,
    isDeleted: false,
    cdoOf: 666
  },
  creationDate: new Date().toString(),
  text: "",
  image: "",
  title: "",
  description: "",
  url: "",
  tags: [],
  type: FeedItemPost.POST,
  isBookMarked: false
};

export const FullNewsCardFixture: Post = {
  id: "123",
  user: userToto,
  creationDate:
    "Fri Sep 29 1989 00:00:00 GMT+0100 (Central European Standard Time)",
  text:
    "TypeScript can and should get better at type inference, higher order functions, and generics. The TypeScript team also has a huge opportunity to improve documentation, including tutorials, videos, best practices, and an easy-to-find rundown of TypeScript’s limitations, which will help TypeScript developers save a lot of time and significantly reduce the costs of using TypeScript.",
  image: bgImage,
  title: "The TypeScript Tax – JavaScript Scene",
  description:
    "TypeScript grew a great deal between 2017 and 2019, and in many ways, for good reason. There’s a lot to love about TypeScript. In the 2018 State of JavaScript survey, almost half the respondents said…",
  url: "http://www.engie.com",
  tags: tags,
  type: FeedItemPost.POST,
  isBookMarked: true
};

export const FullNewsCardFixtureWithNoImage: Post = {
  id: "1234",
  user: userRoman,
  creationDate:
    "Fri Sep 29 1989 00:00:00 GMT+0100 (Central European Standard Time)",
  text:
    "TypeScript can and should get better at type inference, higher order functions, and generics. The TypeScript team also has a huge opportunity to improve documentation, including tutorials, videos, best practices, and an easy-to-find rundown of TypeScript’s limitations, which will help TypeScript developers save a lot of time and significantly reduce the costs of using TypeScript.",
  title: "The TypeScript Tax – JavaScript Scene",
  description:
    "TypeScript grew a great deal between 2017 and 2019, and in many ways, for good reason. There’s a lot to love about TypeScript. In the 2018 State of JavaScript survey, almost half the respondents said…",
  url: "http://www.engie.com",
  type: FeedItemPost.POST,
  isBookMarked: false,
  tags: tags
};

export const ArticleNewsCardFixture: Post = {
  id: "456",
  image: bgImage,
  creationDate:
    "Fri Sep 29 1989 00:00:00 GMT+0100 (Central European Standard Time)",
  user: userFlorent,
  title: "Article Title",
  description: "This is the summary of the shared article.",
  url: "http://www.engie.com",
  type: FeedItemPost.POST,
  isBookMarked: true,
  tags: tags
};

export const TextNewsCardFixture: Post = {
  id: "789",
  user: userToto,
  creationDate:
    "Fri Sep 29 1989 00:00:00 GMT+0100 (Central European Standard Time)",
  text:
    "I've got an idea for a data challlenge : Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  type: FeedItemPost.POST,
  isBookMarked: false,
  tags: tags
};
