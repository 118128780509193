import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import { connect } from "redux-bundler-react";
import { Loader } from "../../components/Loader/Loader";
import { RadioFilters } from "../../components/RadioFilters/RadioFilters";
import { ReactComponent as NotificationIcon } from "../../images/svg/icons/icon-inbox-full.svg";
import {
  notificationFrequencies,
  userNotificationsType
} from "../../types/notifications";
import { getNotificationsConfig } from "./NotificationsConfig";

export interface PropsTypes {
  doFetchUserNotifications: () => Promise<userNotificationsType>;
  doPostUserNotifications: (
    userNotifications: userNotificationsType
  ) => Promise<null>;

}

export const Notifications: FunctionComponent<PropsTypes> = ({
  doFetchUserNotifications,
  doPostUserNotifications
}) => {
  const [notificationsFilter, setNotificationsFilter] = useState<
    userNotificationsType
  >({
    newCommentOnUC: notificationFrequencies.UNDEFINED,
    newCommentOnFollowedUC: notificationFrequencies.UNDEFINED,
    newUC: notificationFrequencies.UNDEFINED
  });
  const [isUserNotificationsLoading, setIsUserNotificationsLoading] = useState<
    boolean
  >(true);
  const [
    isUserUpdatingNotifications,
    setIsUserUpdatingNotifications
  ] = useState<boolean>(false);

  useEffect(() => {
    doFetchUserNotifications()
      .then(setNotificationsFilter)
      .then(() => setIsUserNotificationsLoading(false));
  }, []);

  useEffect(() => {
    if (!isUserNotificationsLoading) {
      setIsUserUpdatingNotifications(true);
      doPostUserNotifications(notificationsFilter).then(() =>
        setIsUserUpdatingNotifications(false)
      );
    }
  }, [notificationsFilter]);

  function onFilterChange({ target: { name, value } }: ChangeEvent<any>) {
    setNotificationsFilter(oldNotificationsFilter => {
      return {
        ...oldNotificationsFilter,
        [name]: value
      };
    });
  }

  return (
    <div className="container mx-auto pt-8 text-center justify-center">
      <h1 className="flex justify-center items-center font-bold text-2xl text-neutral-8">
        <NotificationIcon className={"fill-current h-8 w-auto mr-2"} />
        <Trans id="Notifications" component={null}>Notifications</Trans>
      </h1>
      <div className="h-2 mt-2">
        <Loader isLoading={isUserUpdatingNotifications} dark />
      </div>
      {isUserNotificationsLoading ? (
        <div>
          <Loader isLoading dark />
        </div>
      ) : (
        <table className="mx-auto text-neutral-8">
          <tbody>
            {getNotificationsConfig().map(notificationConfig => (
              <tr key={notificationConfig.name}>
                <td className="px-4 text-left">{notificationConfig.label}</td>
                <td>
                  <RadioFilters
                    selectedValue={notificationsFilter[notificationConfig.name]}
                    onFilterChange={onFilterChange}
                    radioItems={[
                      {
                        label: i18n._(t`Yes`),
                        value: notificationFrequencies.LIVE
                      },
                      {
                        label: i18n._(t`No`),
                        value: notificationFrequencies.NONE
                      }
                    ]}
                    name={notificationConfig.name}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default connect(
  "doFetchUserNotifications",
  "doPostUserNotifications",
  (Notifications)
);
