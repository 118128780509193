import { FunctionComponent } from "react";
import { languages } from "../../types/languages";
import classNames from "classnames";

export interface PropTypes {
  language: languages;
}

export const LanguageBox: FunctionComponent<PropTypes> = ({ language }) => {
  return (
    <div
      className={classNames(
        "text-xs rounded-full w-8 h-8 flex items-center justify-center text-neutral-1 text-lg z-10 uppercase",
        {
          "bg-success-9": language === languages.ENGLIGH,
          "bg-primary-9": language === languages.FRENCH
        }
      )}
      title={language}
    >
      {language}
    </div>
  );
};
