export type TagType = {
  id: number;
  label: string;
  category: string;
  bu_id?: number;
};

export type TagKpiType = {
  label: string;
  category: string;
  projects: number;
  contributors: number;
  posts: number;
  initiatives: number;
  newContributions: number;
  industrializationProjects: number;
  scopingProjects: number;
};

export type BuTagKpiType = {
  label: string;
  category: string;
  projects: number;
  contributors: number;
  newContributions: number;
  industrializationProjects: number;
  scopingProjects: number;
};

export enum MacroKpi {
  NEW_CONTRIBUTIONS = "newContributions",
  INDUSTRIALIZATION_PROJECTS = "industrializationProjects",
  SCOPING_PROJECTS = "scopingProjects",
  PILOT_PROJECTS = "pilotProjects",
  PRODUCTION_PROJECTS = "productionProjects"
}

export enum TagCategories {
  METIER = "Metier",
  TECH = "Tech",
  OTHER = "Other",
  BU = "BU",
  DATATYPE = "Datatype",
  BE = "BE",
  ZBU = "ZBU",
  ZBE = "ZBE"
}

export enum TagLink {
  TAG = "tags",
  BU = "bus",
  BE = "bes"
}
